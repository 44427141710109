const isEmpty = (filter) =>
  filter === [] || filter === {} || filter === null || filter === undefined;

export const anyFilters = (filters: object): boolean => {
  return Object.values(filters).filter((filter) => !isEmpty(filter)).length > 0;
};

export const removeEmptyFilters = (filters: object): object => {
  const nonEmptyFilters = Object.entries(filters).filter(([_, filter]) => !isEmpty(filter));

  return Object.fromEntries(nonEmptyFilters);
};
