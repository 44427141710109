import { FC, createContext, useContext, useState } from 'react';

interface ContextValues {
  dialogOpen: boolean;
  loading: boolean;
  navDrawerOpen: boolean;
  setDialogOpen: (v: boolean) => void;
  setLoading: (v: boolean) => void;
  setNavDrawerOpen: (v: boolean) => void;
}

export const PageContext = createContext<ContextValues>({
  dialogOpen: false,
  loading: false,
  navDrawerOpen: false,
  setDialogOpen: () => null,
  setLoading: () => null,
  setNavDrawerOpen: () => null,
});

export const usePageContext = (): ContextValues => useContext(PageContext);

export const PageContextProvider: FC<{}> = ({ children }) => {
  // When a dialog is open, no key events should be handled by the PageContext
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navDrawerOpen, setNavDrawerOpen] = useState(false);

  return (
    <PageContext.Provider
      value={{
        dialogOpen,
        loading: loading,
        navDrawerOpen,
        setDialogOpen,
        setLoading,
        setNavDrawerOpen,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};
