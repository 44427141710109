import { useLazyQuery } from '@apollo/client';
import Typography from '@material-ui/core/Typography';
import EcoOutlinedIcon from '@material-ui/icons/EcoOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import { PageHeading } from 'components/PageHeading';
import { Paper } from 'components/Paper';
import { GlobalState, useStateMachine } from 'little-state-machine';
import { useForm, Controller } from 'react-hook-form';
import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import {
  GetCompanyCampaigns,
  GetCompanyCampaignsVariables,
  GetCompanyCampaigns_getCampaigns_items,
} from 'types/apollo/GetCompanyCampaigns';
import { GET_COMPANY_CAMPAIGNS } from './getCompanyCampaigns.graphql';
import { Nav } from '../Nav';
import { StepContainer } from '../StepContainer';
import messages from './messages';
import { Select } from 'components/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import { useAccessControl } from 'hooks/useAccessControl';
import { UserAccess_userAccess_organizations } from 'types/apollo/UserAccess';
import { useStyles } from './styles';
import { ListType } from 'types/apollo/globalTypes';
import { predictLeadListMappings } from '../ListColumnMapper/leadListMappings';
import { Checkbox, FormControlLabel } from '@material-ui/core';

interface IUpdateInput {
  listType: ListType;
  company: UserAccess_userAccess_organizations;
  campaign: GetCompanyCampaigns_getCampaigns_items | null;
  mappings: GlobalState['leadListCreate']['mappings'];
}

const updateListDetails = (
  state: GlobalState,
  { listType, company, campaign, mappings }: IUpdateInput,
) => ({
  ...state,
  leadListCreate: {
    ...state.leadListCreate,
    listType,
    company,
    campaign,
    mappings,
  },
});

const setHasSeenFileUpload = (state: GlobalState, hasSeen: boolean) => ({
  ...state,
  leadListCreate: {
    ...state.leadListCreate,
    hasSeenFileUpload: hasSeen,
  },
});

interface IFormInput {
  listType: ListType;
  companyId: string;
  campaignId: string;
  useCampaign: boolean;
}

export const StepSelectLeadType: FC<RouteComponentProps> = ({ history }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { organizations } = useAccessControl();
  const {
    actions,
    state: { leadListCreate },
  } = useStateMachine({ updateListDetails, setHasSeenFileUpload });
  const { control, handleSubmit, watch, setValue } = useForm<IFormInput>({
    defaultValues: {
      listType: leadListCreate.listType || ListType.PROSPECTIVE,
      companyId: `${leadListCreate?.company?.id || ''}`,
      campaignId: `${leadListCreate?.campaign?.id || ''}`,
      useCampaign: false,
    },
  });
  const [goGreen, setGoGreen] = useState(false);

  const [getCompanyCampaigns, getCompanyCampaignsResult] = useLazyQuery<
    GetCompanyCampaigns,
    GetCompanyCampaignsVariables
  >(GET_COMPANY_CAMPAIGNS);

  const listType = watch('listType');
  const companyId = watch('companyId');
  const campaignId = watch('campaignId');
  const useCampaign = watch('useCampaign');

  const nextStep = () => {
    history.push(`/lead-list-import/map-columns`);
    // setGoGreen(true);
    // setTimeout(() => {
    //   history.push(`/lead-list-import/map-columns`);
    // }, 1000);
  };

  const onSubmit = handleSubmit((data) => {
    const selectedLeadType = data.listType;
    const selectedCompany = organizations.find((org) => `${org.id}` === data.companyId);

    if (!selectedCompany) return;

    const selectedCampaign = getCompanyCampaignsResult.data?.getCampaigns?.items.find(
      (c) => `${c.id}` === data.campaignId,
    );

    const mappings = predictLeadListMappings(
      leadListCreate.fileHeaders ?? [],
      companyId,
      selectedLeadType,
    );

    if (selectedLeadType === ListType.DNC) {
      actions.updateListDetails({
        listType: selectedLeadType,
        company: selectedCompany,
        campaign: null,
        mappings,
      });
      nextStep();
      return;
    }

    actions.updateListDetails({
      listType: selectedLeadType,
      company: selectedCompany,
      campaign: selectedCampaign ?? null,
      mappings,
    });
    nextStep();
    return;
  });

  useEffect(() => {
    if (leadListCreate.file?.name) {
      setTimeout(() => {
        actions.setHasSeenFileUpload(true);
      }, 1000);
    }
  }, []);

  useEffect(() => {
    if (!companyId) {
      setValue('campaignId', '');
      return;
    }
    if (companyId) {
      if (listType !== ListType.DNC) {
        getCompanyCampaigns({
          variables: {
            companyId: parseInt(companyId, 10),
            pagination: { limit: 100 },
          },
        });
        return;
      } else if (!leadListCreate.company) {
        onSubmit();
        return;
      }
    }
  }, [companyId, listType]);

  useEffect(() => {
    if (!leadListCreate.hasSeenLeadType && campaignId) {
      onSubmit();
      return;
    }
  }, [campaignId]);

  useEffect(() => {
    if (useCampaign === false) {
      setValue('campaignId', '');
    }
  }, [useCampaign]);

  if (!leadListCreate.file?.name) {
    return <Redirect to={`/lead-list-import`} />;
  }

  const sortedCampaigns = getCompanyCampaignsResult.data?.getCampaigns?.items?.length
    ? [...getCompanyCampaignsResult.data?.getCampaigns?.items]
        .filter((camp) => camp.isRealtime !== '1')
        .sort((a, b) => ((a.campaignName ?? '') < (b.campaignName ?? '') ? -1 : 1))
    : [];

  const sortedOrgs = [...organizations].sort((a, b) =>
    (a.companyName ?? '') < (b.companyName ?? '') ? -1 : 1,
  );

  const useCampaignDropDown =
    listType === ListType.PROSPECTIVE ||
    listType === ListType.CLOSE ||
    (listType === ListType.ENDCONTACT && useCampaign);

  return (
    <form onSubmit={onSubmit}>
      <StepContainer>
        <PageHeading title={formatMessage(messages.assignTitle)} />

        <Typography variant="body1" className={classes.fileName}>
          {leadListCreate.file.name}
        </Typography>

        <Paper
          variant="outlined"
          shade={goGreen ? 'success' : undefined}
          className={classes.paperContainer}
        >
          <div className={classes.selectContainer}>
            <Controller
              name="listType"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  label={formatMessage(messages.listType)}
                  className={clsx(classes.select, {
                    [classes.selectRed]: field.value === ListType.DNC,
                    [classes.selectOrange]: field.value === ListType.CLOSE,
                    [classes.selectGreen]: field.value === ListType.PROSPECTIVE,
                    [classes.selectYellow]: field.value === ListType.ENDCONTACT,
                  })}
                >
                  <MenuItem value={ListType.PROSPECTIVE} className={classes.greenOnHover}>
                    <Box display="flex" alignItems="center">
                      <EcoOutlinedIcon style={{ marginRight: '4px' }} color="inherit" />
                      <Typography color="inherit">{formatMessage(messages.new)}</Typography>
                    </Box>
                  </MenuItem>
                  <MenuItem value={ListType.CLOSE} className={classes.orangeOnHover}>
                    <Box display="flex" alignItems="center">
                      <MonetizationOnOutlinedIcon style={{ marginRight: '4px' }} color="inherit" />
                      <Typography color="inherit">{formatMessage(messages.closed)}</Typography>
                    </Box>
                  </MenuItem>
                  <MenuItem value={ListType.ENDCONTACT} className={classes.yellowOnHover}>
                    <Box display="flex" alignItems="center">
                      <NotInterestedIcon style={{ marginRight: '4px' }} color="inherit" />
                      <Typography color="inherit">{formatMessage(messages.endContact)}</Typography>
                    </Box>
                  </MenuItem>
                  <MenuItem value={ListType.DNC} className={classes.redOnHover}>
                    <Box display="flex" alignItems="center">
                      <NotInterestedIcon style={{ marginRight: '4px' }} color="inherit" />
                      <Typography color="inherit">
                        {formatMessage(messages.doNotContact)}
                      </Typography>
                    </Box>
                  </MenuItem>
                </Select>
              )}
            />
            <Controller
              name="companyId"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  label={formatMessage(messages.company)}
                  className={classes.select}
                >
                  {sortedOrgs.map((org) => (
                    <MenuItem key={org.id} value={`${org.id}`}>
                      {org.companyName}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            {listType === ListType.ENDCONTACT && (
              <Controller
                name="useCampaign"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={useCampaign}
                        onChange={() => {
                          setValue('useCampaign', !useCampaign);
                        }}
                      />
                    }
                    label="End contact for campaign"
                  />
                )}
              />
            )}
            {useCampaignDropDown && (
              <Controller
                name="campaignId"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label={formatMessage(messages.campaign)}
                    className={classes.select}
                    disabled={!companyId}
                  >
                    {!sortedCampaigns.length && (
                      <MenuItem key="loading" value={0}>
                        Loading Campaigns...
                      </MenuItem>
                    )}
                    {sortedCampaigns.map((campaign) => (
                      <MenuItem key={campaign.id} value={`${campaign.id}`}>
                        {campaign.campaignName}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            )}
          </div>
        </Paper>
        <Nav
          onBackClick={history.goBack}
          onForwardClick={
            leadListCreate.hasSeenLeadType ||
            (listType === ListType.CLOSE && !!companyId) ||
            (listType === ListType.ENDCONTACT && !useCampaign)
              ? onSubmit
              : undefined
          }
          disableForward={
            goGreen || !companyId || (listType === ListType.PROSPECTIVE && !campaignId)
          }
          disableBack={goGreen}
        />
      </StepContainer>
    </form>
  );
};
