import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import EcoOutlinedIcon from '@material-ui/icons/EcoOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import clsx from 'clsx';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { ListType } from 'types/apollo/globalTypes';
import messages from './messages';

interface LeadListTypeIconProps {
  listType: ListType | number;
  withLabel?: boolean;
  className?: string;
  style?: any;
}

const useStyles = makeStyles((theme) => ({
  container: {},
  label: {
    marginLeft: theme.spacing(1),
  },
  dnc: {
    color: theme.palette.error[500],
  },
  endContact: {
    color: theme.palette.warning[500],
  },
  close: {
    color: '#FFB81C',
  },
  prospective: {
    color: theme.palette.success[500],
  },
}));

export const LeadListTypeIcon: FC<LeadListTypeIconProps> = ({
  listType,
  className,
  withLabel = false,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  let icon;
  let label;
  switch (typeof listType === 'number' ? getListTypeNameByValue(listType) : listType) {
    case ListType.DNC:
      icon = <NotInterestedIcon className={classes.dnc} {...props} />;
      label = (
        <Typography className={clsx(classes.label, classes.dnc)}>
          {formatMessage(messages.dnc)}
        </Typography>
      );
      break;
    case ListType.ENDCONTACT:
      icon = <NotInterestedIcon className={classes.endContact} {...props} />;
      label = (
        <Typography className={clsx(classes.label, classes.endContact)}>
          {formatMessage(messages.endContact)}
        </Typography>
      );
      break;
    case ListType.CLOSE:
      icon = <MonetizationOnOutlinedIcon className={classes.close} {...props} />;
      label = (
        <Typography className={clsx(classes.label, classes.close)}>
          {formatMessage(messages.close)}
        </Typography>
      );
      break;
    case ListType.PROSPECTIVE:
    default:
      icon = <EcoOutlinedIcon className={classes.prospective} {...props} />;
      label = (
        <Typography className={clsx(classes.label, classes.prospective)}>
          {formatMessage(messages.prospective)}
        </Typography>
      );
  }
  return (
    <Box className={clsx(className, classes.container)} display="flex" alignItems="center">
      {icon}
      {withLabel && label}
    </Box>
  );
};

export const getListTypeValueByName = (listType: ListType): number => {
  switch (listType) {
    case ListType.PROSPECTIVE:
      return 0;
    case ListType.DNC:
      return 1;
    case ListType.CLOSE:
      return 2;
    case ListType.ENDCONTACT:
      return 3;
  }
};

export const getListTypeNameByValue = (listType: number): ListType | null => {
  switch (listType) {
    case 0:
      return ListType.PROSPECTIVE;
    case 1:
      return ListType.DNC;
    case 2:
      return ListType.CLOSE;
    case 3:
      return ListType.ENDCONTACT;
  }
  return null;
};
