import makeStyles from '@material-ui/core/styles/makeStyles';
import { FC } from 'react';
import { AppNavDrawerListButton } from './AppNavDrawerListButton';
import { AppNavDrawerListItemAccordion } from './AppNavDrawerListItemAccordion';
import { NavListItem } from './AppNavDrawer.types';

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export const AppNavDrawerListItem: FC<AppNavDrawerListButtonProps> = ({
  className,
  onClick,
  item,
}) => {
  const classes = useStyles();

  if ('to' in item) {
    return (
      <AppNavDrawerListButton className={className} item={item} onClick={onClick} key={item.id} />
    );
  }

  if ('component' in item) {
    const { component: Component } = item;
    return <Component className={className} onClick={onClick} key={item.id} />;
  }

  // Nav drawer items can be nested. WARNING: Only one level of nesting is acceptable
  if ('items' in item) {
    return (
      <AppNavDrawerListItemAccordion icon={item.icon} label={item.label} key={item.id}>
        {item.items.map((item) => (
          <AppNavDrawerListItem
            className={classes.nested}
            item={item}
            key={item.id}
            onClick={onClick}
          />
        ))}
      </AppNavDrawerListItemAccordion>
    );
  }

  return null;
};

interface AppNavDrawerListButtonProps {
  className?: string;
  item: NavListItem;
  onClick: () => void;
}
