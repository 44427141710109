import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { FC } from 'react';

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'center',
    width: '100%',
  },
}));

/**
 * Base component and ecosystem for empty state components. Provides an interface for displaying images, primary and
 * secondary text, as well as actions in a consistent manner.
 */
export const EmptyState: FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Box
      alignItems="center"
      className={classes.root}
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      {children}
    </Box>
  );
};
