import { FC, createContext, useCallback, useContext, useState } from 'react';
import { Notification } from './Notification';

export type Severity = 'error' | 'success';

type NotificationObject = { message: string; open: boolean; severity: Severity };

interface NotificationContextObject {
  notify: (message: string, key: string, severity: Severity) => void;
  notifyError: (message: string, key: string) => void;
  notifySuccess: (message: string, key: string) => void;
}

export const NotificationContext = createContext<NotificationContextObject>({
  notify: () => null,
  notifyError: () => null,
  notifySuccess: () => null,
});

export const useNotifications = (): NotificationContextObject => useContext(NotificationContext);

export const NotificationProvider: FC<{}> = ({ children }) => {
  const [notifications, setNotifications] = useState<{ [key: string]: NotificationObject }>({});

  const notify = useCallback(
    (message, key, severity) => {
      setNotifications({
        ...notifications,
        [key]: { message, open: true, severity },
      });
    },
    [notifications, setNotifications],
  );

  const notifyError = useCallback(
    (message, key) => {
      notify(message, key, 'error');
    },
    [notify],
  );

  const notifySuccess = useCallback(
    (message, key) => {
      notify(message, key, 'success');
    },
    [notify],
  );

  const handleClose = useCallback(
    (key) => () => {
      // Hide the notification
      setNotifications({
        ...notifications,
        [key]: {
          ...notifications[key],
          open: false,
        },
      });
    },
    [notifications, setNotifications],
  );

  const handleExited = useCallback(
    (key) => () => {
      // Clean the notification by removing it
      const { [key]: _, ...rest } = notifications;

      setNotifications(rest);
    },
    [notifications, setNotifications],
  );

  return (
    <NotificationContext.Provider
      value={{
        notify,
        notifyError,
        notifySuccess,
      }}
    >
      {children}
      <div>
        {Object.entries(notifications).map(([key, { message, open, severity }]) => (
          <Notification
            key={key}
            message={message}
            onClose={handleClose(key)}
            onExited={handleExited(key)}
            open={open}
            severity={severity}
          />
        ))}
      </div>
    </NotificationContext.Provider>
  );
};
