import { defineMessages } from 'react-intl';

export default defineMessages({
  companyName: {
    id: 'global.companyName',
    defaultMessage: 'Aktify',
  },
  workspaceDashboard: {
    id: 'workspace.Dashboard',
    defaultMessage: 'Dashboard',
  },
  organization: {
    id: 'workspace.Organization',
    defaultMessage: 'Organization',
  },
  organizationUsers: {
    id: 'workspace.OrganizationUsers',
    defaultMessage: 'Users',
  },
  workspaceCampaigns: {
    id: 'workspace.Campaigns',
    defaultMessage: 'Campaigns',
  },
  workspacePhoneNumbers: {
    id: 'workspace.PhoneNumbers',
    defaultMessage: 'Phone Numbers',
  },
  workspacePhoneLookups: {
    id: 'workspace.PhoneLookup',
    defaultMessage: 'Phone Lookup',
  },
  workspaceDNCs: {
    id: 'workspace.DNCs',
    defaultMessage: 'DNC',
  },
  workspaceDNCComplainers: {
    id: 'workspace.DNCComplainers',
    defaultMessage: 'DNC Complainer',
  },
  workspaceDNCLitigators: {
    id: 'workspace.DNCLitigators',
    defaultMessage: 'DNC Litigator',
  },
  workspaceAccount: {
    id: 'workspace.Account',
    defaultMessage: 'Account',
  },
});
