import qs from 'qs';
import Paper from '@material-ui/core/Paper';
import { DataTableContainerProps } from 'components/DataTable';
import { ChangeEvent, ComponentType, useCallback, useEffect } from 'react';
import { MessageDescriptor } from 'react-intl';
import { PageInfo } from 'types';
import { PageConfigurationQueryString } from 'utils/pageConfigurationUtils';
import { anyFilters } from '../../utils/filterUtils';
import { Pagination, SortDirection } from 'types/apollo/globalTypes';
import { TableBody } from './TableBody';
import { useHistory, useLocation } from 'react-router-dom';

export interface TableViewProps<T = { id: string | number }> {
  called?: boolean;
  children?: () => JSX.Element;
  defaultSortDirection?: SortDirection;
  defaultSortField?: string;
  filters?: object;
  loadData: (p: Pagination) => void;
  pageInfo?: PageInfo;
  loading: boolean;
  error: boolean;
  items: Array<T>;
  pageTitleMessage: MessageDescriptor;
  search?: string;
  searchEnabled?: boolean;
  tableComponent: ComponentType<Omit<DataTableContainerProps<T>, 'columns' | 'ariaLabel'>>;
}

export function Table<T>({
  called,
  filters = {},
  loadData,
  pageTitleMessage,
  loading,
  error,
  pageInfo,
  items,
  search,
  defaultSortDirection,
  defaultSortField,
  tableComponent,
}: TableViewProps<T>): JSX.Element {
  const history = useHistory();
  const location = useLocation();

  const pageConfiguration = qs.parse(location.search, { ignoreQueryPrefix: true });
  let { limit, page } = pageConfiguration;
  const sortDirection = pageConfiguration.sortDirection || defaultSortDirection;
  const sortField = pageConfiguration.sortField || defaultSortField;

  if (limit) {
    // @ts-ignore
    limit = parseInt(limit, 10);
  }
  if (page) {
    // @ts-ignore
    page = parseInt(page, 10);
  }

  const handleChange = useCallback(
    (value: PageConfigurationQueryString | null) => {
      const pagination = {
        limit,
        page,
        search,
        sortDirection,
        sortField,
        ...value,
      };
      history.replace({
        search: qs.stringify(pagination, { addQueryPrefix: true }),
      });
      //@ts-ignore
      loadData(pagination);
    },
    [history, loadData, limit, page, search, sortDirection, sortField],
  );

  const handleSortChange = useCallback(
    ([sortField, sortDirection]: [string, SortDirection]) => {
      handleChange({ sortField, sortDirection });
    },
    [handleChange],
  );

  const handleChangePage = useCallback(
    (page: number) => {
      handleChange({ page });
    },
    [handleChange],
  );

  const handleChangeRowsPerPage = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const limit = parseInt(event.target.value, 10);
      handleChange({ limit, page: 1 });
    },
    [handleChange],
  );

  // Trigger the initial query. This only runs once. All subsequent onChange events are
  // handled by the individual event handlers.
  useEffect(() => {
    handleChange(null);
    // eslint-disable-next-line
  }, []);

  const filtersApplied = anyFilters(filters);

  return (
    <Paper variant="outlined" style={{ width: '100%', borderRadius: '16px' }}>
      <TableBody
        called={called}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onSort={handleSortChange}
        loading={loading}
        error={error}
        items={items}
        pageInfo={pageInfo}
        title={pageTitleMessage}
        resultsFiltered={filtersApplied || !!search}
        sortDirection={sortDirection as SortDirection}
        sortField={sortField as string}
        tableComponent={tableComponent}
      />
    </Paper>
  );
}
