import { defineMessages } from 'react-intl';

export default defineMessages({
  companyTitle: {
    id: 'LeadListCard.companyTitle',
    defaultMessage: 'Company',
  },
  campaignTitle: {
    id: 'LeadListCard.campaignTitle',
    defaultMessage: 'Campaign',
  },
  dateTitle: {
    id: 'LeadListCard.dateTitle',
    defaultMessage: 'Date Imported',
  },
  importAuthorTitle: {
    id: 'LeadListCard.importAuthorTitle',
    defaultMessage: 'Imported By',
  },
  sendToCampaign: {
    id: 'LeadListCard.sendToCampaign',
    defaultMessage: 'Send to Campaign',
  },
  estimatedLeads: {
    id: 'LeadListCard.estimatedLeads',
    defaultMessage: 'Estimated Leads',
  },
  uploadedBy: {
    id: 'LeadListCard.uploadedBy',
    defaultMessage: 'You',
  },
  deliverability: {
    id: 'LeadListCard.deliverability',
    defaultMessage: 'Deliverability',
  },
  of: {
    id: 'LeadListCard.of',
    defaultMessage: 'of',
  },
  leads: {
    id: 'LeadListCard.leads',
    defaultMessage: 'leads',
  },
  leadTypeTitle: {
    id: 'LeadListCard.leadTypeTitle',
    defaultMessage: 'Lead Type',
  },
  sendConfirmationModalTitle: {
    id: 'LeadListCard.sendConfirmationModalTitle',
    defaultMessage: 'Ready to send?',
  },
  sendConfirmationModalBody: {
    id: 'LeadListCard.sendConfirmationModalBody',
    defaultMessage:
      'The leads on this list will enter the campaign flow and receive texts and calls. In other words, what’s done is done and you won’t be able to undo this action.',
  },
  cancelImportConfirmationModalTitle: {
    id: 'LeadListCard.cancelImportConfirmationModalTitle',
    defaultMessage: 'Cancel Import?',
  },
  cancelImportConfirmationModalBody: {
    id: 'LeadListCard.cancelImportConfirmationModalBody',
    defaultMessage: 'Are you sure you want to stop this import and delete the file?',
  },
  sendToCampaignFailNotification: {
    id: 'sendToCampaignFailNotification',
    defaultMessage: 'Well, this is awkward. Your list import failed. Try again.',
  },
  sendToCampaignSuccessNotification: {
    id: 'sendToCampaignSuccessNotification',
    defaultMessage: 'Mission accomplished! Your import was successful.',
  },
  confirmCancelCancelText: {
    id: 'confirmCancelCancelText',
    defaultMessage: 'Never Mind',
  },
  confirmCancelConfirmationText: {
    id: 'confirmCancelConfirmationText',
    defaultMessage: 'Yes, Cancel the Import and Delete',
  },
});
