import React, { FC } from 'react';
import Modal, { ModalProps } from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

export const BaseModal: FC<ModalProps> = ({ children, ...props }) => {
  return (
    <Modal
      {...props}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 250,
      }}
    >
      <Fade in={props.open}>{children}</Fade>
    </Modal>
  );
};
