import Box from '@material-ui/core/Box';
import { LogoutButton } from 'components/ActionButtons/LogoutButton';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { BugsImage } from 'images';
import messages from './messages';
import { PageEmptyState } from '../components/PageEmptyState';

export const PageError: FC<{}> = (props) => (
  <PageEmptyState
    image={BugsImage}
    primary={<FormattedMessage {...messages.errorStatePrimary} />}
    secondary={<FormattedMessage {...messages.errorStateSecondary} />}
    {...props}
  >
    <Box mt={2}>
      <LogoutButton />
    </Box>
  </PageEmptyState>
);
