import TableRow from '@material-ui/core/TableRow';
import DataTableCell from './DataTableCell';
import { Column } from './dataTable.types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  tableRow: {
    cursor: 'pointer',
  },
}));
interface DataTableRowProps<T> {
  columns: Column<T>[];
  row: T;
  onClick?: (row: T) => void;
}

export function DataTableRow<T>({ columns, row, onClick }: DataTableRowProps<T>): JSX.Element {
  const classes = useStyles();
  return (
    <TableRow
      hover
      onClick={onClick ? () => onClick(row) : undefined}
      className={clsx(!!onClick && classes.tableRow)}
    >
      {columns.map((column: Column<T>) => {
        const data = column.dataGetter({ column, row });
        return (
          <DataTableCell
            align={column.align}
            cellRenderer={column.cellRenderer}
            data={data}
            key={column.dataKey}
            style={column.style}
          />
        );
      })}
    </TableRow>
  );
}
