import { makeStyles } from '@material-ui/core/styles';
import { FC } from 'react';

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    paddingBottom: '24px',
  },
}));

export const StepContainer: FC<{}> = (props) => {
  const classes = useStyles();

  return <div className={classes.pageContainer} {...props} />;
};
