import Container, { ContainerProps } from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { FC } from 'react';

const useStyles = makeStyles((theme) => ({
  appBarSpacer: {
    minHeight: '48px',
  },
  container: {
    display: 'flex',
    flex: 1,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    alignItems: 'center',
    flexDirection: 'column',
  },
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
}));

export const PageContent: FC<PageContentProps> = ({ children, maxWidth = 'lg' }) => {
  const classes = useStyles();
  return (
    <main className={classes.root}>
      <div className={classes.appBarSpacer} />
      <Container className={classes.container} maxWidth={maxWidth}>
        <>{children}</>
      </Container>
    </main>
  );
};

export type PageContentProps = Pick<ContainerProps, 'maxWidth'>;
