import { FC } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import { PageContextProvider } from '../PageContent/pageContext';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
}));

export const AppShell: FC<{}> = ({ children }) => {
  const classes = useStyles();

  return (
    <PageContextProvider>
      <div className={classes.root}>
        <CssBaseline />
        {children}
      </div>
    </PageContextProvider>
  );
};
