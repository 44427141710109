import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Box from '@material-ui/core/Box';
import { PageInfo } from '../../types';

import { IconButton } from '../IconButton';

import { FC } from 'react';

export const TableMinimalPagination: FC<TableMinimalPaginationProps> = ({
  pageInfo,
  onChangePage,
}) => {
  return (
    <Box display="flex" justifyContent="flex-end">
      {pageInfo?.hasPrev ? (
        <IconButton
          ariaLabel="Page left"
          onClick={(e) => onChangePage(e, pageInfo.page - 2)}
          style={{ marginRight: '8px' }}
        >
          <ChevronLeftIcon />
        </IconButton>
      ) : null}
      {pageInfo?.hasNext && pageInfo.page < pageInfo.pages ? (
        <IconButton ariaLabel="Page right" onClick={(e) => onChangePage(e, pageInfo.page)}>
          <ChevronRightIcon />
        </IconButton>
      ) : (
        <div style={{ width: '48px' }} />
      )}
    </Box>
  );
};

interface TableMinimalPaginationProps {
  pageInfo?: PageInfo;
  onChangePage: (event: any, page: number) => void;
}

export default TableMinimalPagination;
