import React, { FC, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

export const PageLoading: FC<{}> = () => {
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    const to = setTimeout(() => {
      setShowSpinner(true);
    }, 1000);
    return () => {
      clearTimeout(to);
    };
  }, []);
  return (
    <Box alignItems="center" display="flex" height="100%" justifyContent="center" width="100%">
      {showSpinner && <CircularProgress />}
    </Box>
  );
};
