import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import React, { FC, ReactElement, useCallback } from 'react';
import messages from '../messages';
import { useIntl } from 'react-intl';
import { Select } from 'components/Select';
import { RightArrowIcon } from 'icons';
import { leadListMappings } from './leadListMappings';
import { LinearProgress } from 'components/LinearProgress';
import { ListType } from 'types/apollo/globalTypes';

interface LeadListColumnTypeMappingProps {
  columnName: string;
  error?: boolean;
  onChange: (val: { [key: string]: string }) => void;
  value?: string;
  currentMappings: string[];
}

const useStyles = makeStyles((theme) => ({
  pad: {
    paddingLeft: theme.spacing(1),
  },
}));

const LeadListColumnTypeMapping: FC<LeadListColumnTypeMappingProps> = ({
  columnName,
  error,
  onChange,
  value = 'unmapped',
  currentMappings,
}) => {
  const { formatMessage } = useIntl();

  const classes = useStyles();

  const handleChange = useCallback(
    (event) => {
      onChange({ [columnName]: event.target.value });
    },
    [columnName, onChange],
  );

  return (
    <Grid container item alignItems="center">
      <Grid item xs={4} className={classes.pad}>
        <Typography variant="body1" noWrap>
          {columnName}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <RightArrowIcon />
      </Grid>
      <Grid item xs={6}>
        <Select error={error} onChange={handleChange} value={value} variant="outlined">
          <MenuItem value="unmapped">
            <Typography color="textSecondary">
              <em>{formatMessage(messages.selectField)}</em>
            </Typography>
          </MenuItem>
          {leadListMappings.map((mapping) => (
            <MenuItem
              key={mapping.value}
              value={mapping.value}
              disabled={currentMappings.includes(mapping.value) && value !== mapping.value}
            >
              {mapping.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );
};

interface ListColumnMapperProps {
  headers: string[];
  onSetMappings: (value: { [key: string]: string }) => void;
  mappings: { [key: string]: string };
  hasPhone?: boolean;
  hasName?: boolean;
  hasDuplicate?: boolean;
  hasCreated?: boolean;
  listType: ListType;
}

const useStylesMapper = makeStyles((theme) => ({
  foundColumns: {
    marginBottom: theme.spacing(4),
  },
}));

const checkForDuplicates = (currentValue: string, values: { [key: string]: string }) => {
  const count = Object.values(values).reduce((acc, cur) => {
    if (cur === currentValue && cur !== 'unmapped') return acc + 1;
    return acc;
  }, 0);
  return count > 1;
};

export const ListColumnMapper = ({
  headers,
  onSetMappings,
  mappings,
  hasPhone,
  hasName,
  hasDuplicate,
  hasCreated,
  listType,
}: ListColumnMapperProps): ReactElement => {
  const classes = useStylesMapper();
  const { formatMessage } = useIntl();

  const currentMappings = Object.values(mappings);

  const mappingComponent = headers.map((columnName) => {
    const isDuplicated = mappings[columnName]
      ? checkForDuplicates(mappings[columnName], mappings)
      : false;
    return (
      <LeadListColumnTypeMapping
        columnName={columnName}
        error={isDuplicated}
        key={columnName}
        onChange={onSetMappings}
        value={mappings[columnName]}
        currentMappings={currentMappings}
      />
    );
  });

  const progress = (function () {
    if (hasDuplicate) return 0;
    if (listType === ListType.PROSPECTIVE) {
      if (!hasPhone) return 33;
      if (!hasName) return 66;
      return 100;
    }
    if (!hasPhone) return 0;
    if (listType === ListType.CLOSE) {
      if (!hasCreated) return 66;
    }
    return 100;
  })();

  const message = (function () {
    if (hasDuplicate) return formatMessage(messages.confirmationDialogueDuplicate);
    if (!hasPhone) return formatMessage(messages.confirmationDialoguePhoneMissing);
    if (listType === ListType.PROSPECTIVE && !hasName)
      return formatMessage(messages.confirmationDialogueNameMissing);
    if (listType === ListType.CLOSE && !hasCreated)
      return formatMessage(messages.confirmationDialogueNoCreated);
    return formatMessage(messages.confirmationDialoguePerfection);
  })();

  return (
    <Box>
      <Typography variant="body1" className={classes.foundColumns}>
        {headers.length} {formatMessage(messages.columnsFound)}
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="body1" color="textSecondary">
            {formatMessage(messages.csvColumns)}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" color="textSecondary">
            {formatMessage(messages.systemField)}
          </Typography>
        </Grid>
        {mappingComponent}
      </Grid>

      <Box alignItems="center" display="flex" flexDirection="column" mt={6}>
        <Box maxWidth="375px" width="100%" height={5}>
          <LinearProgress
            value={progress}
            variant="determinate"
            color={progress < 66 ? 'orange' : progress < 100 ? 'orange' : 'green'}
          />
        </Box>
        <Box mt={2}>
          <Typography variant="body1" color="textSecondary">
            {message}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
