import { useMutation } from '@apollo/client';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import React, { FC, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import messages from './messages';
import { FormattedNumber, useIntl } from 'react-intl';
import format from 'date-fns/format';
import { Paper } from 'components/Paper';
import { useNotifications } from 'components/notifications';
import {
  ConfirmModal,
  ConfirmModalTitle,
  ConfirmModalBody,
  ConfirmModalActions,
} from 'components/ConfirmModal';
import { useAccessControl } from 'hooks/useAccessControl';
import {
  GetLeadListsForDashboard,
  GetLeadListsForDashboard_getLeadLists_items,
} from 'types/apollo/GetLeadListsForDashboard';
import {
  getShadeColorForLeadList,
  LeadListStatusLabel,
  LEAD_LIST_STATUS_MAPPING,
} from 'components/LeadListStatusLabel';
import SEND_TO_CAMPAIGN from './sendToCampaign.graphql';
import CANCEL_IMPORT from './cancel-import.graphql';
import { CancelImport, CancelImportVariables } from 'types/apollo/CancelImport';
import { SendToCampaign, SendToCampaignVariables } from 'types/apollo/SendToCampaign';
import clsx from 'clsx';
import GET_LEAD_LISTS_FOR_DASHBOARD from '../../getLeadListsForDashboard.graphql';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import isAfter from 'date-fns/isAfter';
import subMinutes from 'date-fns/subMinutes';
import { LeadListTypeIcon } from 'components/LeadListTypeIcon';
import { ListType } from 'types/apollo/globalTypes';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    maxWidth: '530px',
    borderRadius: '16px',
  },
  fileName: {
    marginRight: theme.spacing(2),
    overflowWrap: 'anywhere',
  },
  categoryLabel: {
    marginRight: '2px',
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  moreIcon: {
    color: theme.palette.grey[500],
    marginLeft: theme.spacing(2),
  },
}));

export const LeadListCard: FC<{
  leadList: GetLeadListsForDashboard_getLeadLists_items;
  className?: string;
}> = ({ leadList, ...props }) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [confirmSendModalisOpen, setConfirmSendModalIsOpen] = useState(false);
  const [confirmCancelImportModalisOpen, setConfirmCancelImportModalIsOpen] = useState(false);
  const { id } = useAccessControl();
  const { notifyError, notifySuccess } = useNotifications();
  const previousStatus = useRef(leadList.status);

  const [sendToCampaign, sendToCampaignMutation] = useMutation<
    SendToCampaign,
    SendToCampaignVariables
  >(SEND_TO_CAMPAIGN, {
    update: (cache, res) => {
      const updatedId = res.data?.sendLeadListToCampaign.id;
      const currentLeadLists = cache.readQuery<GetLeadListsForDashboard>({
        query: GET_LEAD_LISTS_FOR_DASHBOARD,
      });
      const getLeadLists = currentLeadLists?.getLeadLists.items || [];
      cache.writeQuery({
        query: GET_LEAD_LISTS_FOR_DASHBOARD,
        data: {
          getLeadLists: getLeadLists.map((item) => {
            if (item.id === updatedId) {
              return { ...item, sentToCampaign: new Date().toISOString() };
            }
            return item;
          }),
        },
      });
    },
  });

  const [cancelImport, cancelImportMutation] = useMutation<CancelImport, CancelImportVariables>(
    CANCEL_IMPORT,
    {
      update: (cache, res) => {
        const updatedId = res.data?.cancelLeadListImport.id;
        const currentLeadLists = cache.readQuery<GetLeadListsForDashboard>({
          query: GET_LEAD_LISTS_FOR_DASHBOARD,
        });
        const getLeadLists = currentLeadLists?.getLeadLists.items || [];
        cache.writeQuery({
          query: GET_LEAD_LISTS_FOR_DASHBOARD,
          data: {
            getLeadLists: getLeadLists.map((item) => {
              if (item.id === updatedId) {
                return { ...item, deleted: new Date().toISOString() };
              }
              return item;
            }),
          },
        });
      },
    },
  );

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const openSendConfirmationModal = () => {
    setConfirmSendModalIsOpen(true);
  };

  const closeSendConfirmationModal = () => {
    setConfirmSendModalIsOpen(false);
  };

  const openConfirmCancelImportModal = () => {
    handleMenuClose();
    setConfirmCancelImportModalIsOpen(true);
  };

  const closeConfirmCancelImportModal = () => {
    setConfirmCancelImportModalIsOpen(false);
  };

  const [shade, setShade] = useState<'warning' | 'success' | 'error' | 'default' | undefined>();
  const timeOutRef = useRef<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    if (leadList.status !== previousStatus.current) {
      previousStatus.current = leadList.status;
      const shade = getShadeColorForLeadList(leadList.status);
      if (shade !== 'info') {
        setShade(shade);
      }
      if (timeOutRef.current) {
        clearTimeout(timeOutRef.current);
      }
      timeOutRef.current = setTimeout(() => {
        setShade(undefined);
      }, 2000);
    }
  }, [leadList]);

  useEffect(() => {
    if (
      (leadList.status === LEAD_LIST_STATUS_MAPPING.PROCESSING ||
        leadList.status === LEAD_LIST_STATUS_MAPPING.UNPROCESSED) &&
      isAfter(new Date(leadList.created), subMinutes(new Date(), 1))
    ) {
      setShade('warning');
      timeOutRef.current = setTimeout(() => {
        setShade(undefined);
      }, 2000);
    }
    return () => {
      if (timeOutRef.current) {
        clearTimeout(timeOutRef.current);
      }
    };
  }, []);

  const handleSendToCampaign = async () => {
    closeSendConfirmationModal();
    try {
      await sendToCampaign({ variables: { id: leadList.id } });
      notifySuccess(
        formatMessage(messages.sendToCampaignSuccessNotification),
        'SendToCampaign-success',
      );
    } catch (err) {
      notifyError(formatMessage(messages.sendToCampaignFailNotification), 'SendToCampaign-error');
    }
  };

  const handleCancelImport = async () => {
    closeConfirmCancelImportModal();
    await cancelImport({ variables: { id: leadList.id } });
  };

  return (
    <Paper className={clsx(classes.container, props.className)} shade={shade}>
      <Box padding={3}>
        <Box display="flex" flexDirection="row" alignItems="center" mb={2}>
          <Box display="flex" flexDirection="row" alignItems="center" flex={1}>
            <Typography variant="subtitle1" className={classes.fileName}>
              {leadList.name}
            </Typography>
            <LeadListStatusLabel status={leadList.status} />
          </Box>

          <IconButton
            edge="end"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleMenuClick}
            className={classes.moreIcon}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MenuItem onClick={openConfirmCancelImportModal}>
              <ListItemIcon>
                <CloseIcon fontSize="default" />
              </ListItemIcon>
              <ListItemText primary="Cancel Import" />
            </MenuItem>
          </Menu>
        </Box>

        <Box mb={2}>
          <Box display="flex" alignItems="center" minHeight="24px">
            <Typography
              color="textSecondary"
              component="span"
              variant="body1"
              className={classes.categoryLabel}
            >
              {formatMessage(messages.companyTitle)}:
            </Typography>{' '}
            <Typography variant="body1">{leadList.company?.companyName}</Typography>
          </Box>
          <Box display="flex" alignItems="center" minHeight="24px">
            <Typography
              color="textSecondary"
              component="span"
              variant="body1"
              className={classes.categoryLabel}
            >
              {formatMessage(messages.campaignTitle)}:
            </Typography>{' '}
            <Typography variant="body1">{leadList.campaign?.campaignName}</Typography>
          </Box>
          <Box display="flex" alignItems="center" minHeight="24px">
            <Typography
              color="textSecondary"
              component="span"
              variant="body1"
              className={classes.categoryLabel}
            >
              {formatMessage(messages.leadTypeTitle)}:
            </Typography>
            <LeadListTypeIcon listType={ListType.PROSPECTIVE} withLabel />
          </Box>
          <Box display="flex" alignItems="center" minHeight="24px">
            <Typography
              color="textSecondary"
              component="span"
              variant="body1"
              className={classes.categoryLabel}
            >
              {formatMessage(messages.dateTitle)}:
            </Typography>{' '}
            <Typography variant="body1">
              {format(new Date(leadList.created), 'MMM d, yyyy h:mm aaa')}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" minHeight="24px">
            <Typography
              color="textSecondary"
              component="span"
              variant="body1"
              className={classes.categoryLabel}
            >
              {formatMessage(messages.importAuthorTitle)}:
            </Typography>{' '}
            <Typography variant="body1">{`${
              leadList.uploadedByFk === id ? `${formatMessage(messages.uploadedBy)} (` : ''
            }${leadList.uploadedBy?.firstName} ${leadList.uploadedBy?.lastName}${
              leadList.uploadedByFk === id ? ')' : ''
            }`}</Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Box>
            {leadList.status === LEAD_LIST_STATUS_MAPPING.PROCESSED && (
              <>
                <Box display="flex" alignItems="center" mb={1}>
                  <Typography variant="h2">
                    {leadList.totalRows
                      ? `${(
                          ((leadList.totalRows - (leadList?.rejected || 0)) / leadList.totalRows) *
                          100
                        ).toFixed(2)}%`
                      : '---'}
                  </Typography>
                  <CheckCircleIcon width="24px" style={{ marginLeft: '8px', color: '#00C389' }} />
                </Box>
                <Typography variant="body2">
                  {`${formatMessage(messages.deliverability)} `}
                  {leadList.totalRows ? (
                    <FormattedNumber value={leadList?.totalRows - (leadList?.rejected || 0)} />
                  ) : (
                    '---'
                  )}{' '}
                  {formatMessage(messages.of)} <FormattedNumber value={leadList.totalRows || 0} />{' '}
                  {formatMessage(messages.leads)}
                </Typography>
              </>
            )}
            {leadList.status !== LEAD_LIST_STATUS_MAPPING.PROCESSED && (
              <>
                <Typography variant="h2" gutterBottom>
                  <FormattedNumber value={leadList.estimatedTotalRows || 0} />
                </Typography>
                <Typography variant="body2">{formatMessage(messages.estimatedLeads)}</Typography>
              </>
            )}
          </Box>
          {(sendToCampaignMutation.loading || cancelImportMutation.loading) && <CircularProgress />}
          {!sendToCampaignMutation.loading && !cancelImportMutation.loading && (
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={openSendConfirmationModal}
              disabled={leadList.status !== LEAD_LIST_STATUS_MAPPING.PROCESSED}
            >
              {formatMessage(messages.sendToCampaign)}
            </Button>
          )}
        </Box>
      </Box>

      <ConfirmModal
        open={confirmSendModalisOpen}
        onClose={sendToCampaignMutation.loading ? () => null : closeSendConfirmationModal}
      >
        <ConfirmModalTitle>{formatMessage(messages.sendConfirmationModalTitle)}</ConfirmModalTitle>
        <ConfirmModalBody>{formatMessage(messages.sendConfirmationModalBody)}</ConfirmModalBody>
        <ConfirmModalActions
          onClose={sendToCampaignMutation.loading ? () => null : closeSendConfirmationModal}
          onConfirm={handleSendToCampaign}
        />
      </ConfirmModal>

      <ConfirmModal
        open={confirmCancelImportModalisOpen}
        onClose={cancelImportMutation.loading ? () => null : closeConfirmCancelImportModal}
      >
        <ConfirmModalTitle>
          {formatMessage(messages.cancelImportConfirmationModalTitle)}
        </ConfirmModalTitle>
        <ConfirmModalBody>
          {formatMessage(messages.cancelImportConfirmationModalBody)}
        </ConfirmModalBody>
        <ConfirmModalActions
          onClose={cancelImportMutation.loading ? () => null : closeConfirmCancelImportModal}
          onConfirm={handleCancelImport}
          cancelText={formatMessage(messages.confirmCancelCancelText)}
          confirmText={formatMessage(messages.confirmCancelConfirmationText)}
        />
      </ConfirmModal>
    </Paper>
  );
};
