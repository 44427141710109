import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { NoResultsImage } from 'images';
import messages from './messages';
import { PageEmptyState } from '../components/PageEmptyState';

export const PageEmptyResults: FC<{}> = (props) => (
  <PageEmptyState
    image={NoResultsImage}
    primary={<FormattedMessage {...messages.noResultsPrimary} />}
    secondary={<FormattedMessage {...messages.noResultsSecondary} />}
    {...props}
  />
);
