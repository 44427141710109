import { defineMessages } from 'react-intl';

export default defineMessages({
  leadType: {
    id: 'CampaignSelector.leadType',
    defaultMessage: 'Lead Type',
  },
  company: {
    id: 'CampaignSelector.company',
    defaultMessage: 'Company',
  },
  campaign: {
    id: 'CampaignSelector.campaign',
    defaultMessage: 'Campaign',
  },
  selectCampaign: {
    id: 'CampaignSelector.selectCampaign',
    defaultMessage: 'Select Campaign',
  },
  selectField: {
    id: 'ColumnMapper.selectField',
    defaultMessage: 'Select Field',
  },
  columnsFound: {
    id: 'ColumnMapper.columnsFound',
    defaultMessage: ' columns found.',
  },
  csvColumns: {
    id: 'ColumnMapper.csvColumns',
    defaultMessage: 'CSV Columns',
  },
  systemField: {
    id: 'ColumnMapper.systemField',
    defaultMessage: 'System Field',
  },
  confirmationDialogueDuplicate: {
    id: 'ColumnMapper.confirmationDialogueDuplicate',
    defaultMessage: 'Duplicate mappings not allowed.',
  },
  confirmationDialoguePhoneMissing: {
    id: 'ColumnMapper.confirmationDialoguePhoneMissing',
    defaultMessage: 'Phone Missing',
  },
  confirmationDialogueNameMissing: {
    id: 'ColumnMapper.confirmationDialogueNameMissing',
    defaultMessage: 'First or Full Name?',
  },
  confirmationDialogueNoCreated: {
    id: 'ColumnMapper.confirmationDialogueNoCreated',
    defaultMessage: 'Created Date?',
  },
  confirmationDialoguePerfection: {
    id: 'ColumnMapper.confirmationDialoguePerfection',
    defaultMessage: 'Perfection.',
  },
  continueToReview: {
    id: 'ColumnMapper.continueToReview',
    defaultMessage: 'Continue to Review Import',
  },
  reviewLeadNumberImportedNew: {
    id: 'ListReview.reviewLeadNumberImportedNew',
    defaultMessage: 'leads will be imported and automagically cleansed.',
  },
  reviewLeadNumberImportedDnc: {
    id: 'ListReview.reviewLeadNumberImportedDnc',
    defaultMessage: 'leads will be marked as ‘DNC’ and will no longer receive messages.',
  },
  reviewLeadNumberImportedEndContact: {
    id: 'ListReview.reviewLeadNumberImportedEndContact',
    defaultMessage: 'leads will be marked as ‘End Contact’ and will no longer receive messages.',
  },
  reviewLeadNumberImportedClose: {
    id: 'ListReview.reviewLeadNumberImportedClose',
    defaultMessage: 'leads will be marked as ‘Closed’ and will no longer receive messages.',
  },
  reviewClarification: {
    id: 'ListReview.reviewClarification',
    defaultMessage:
      "The list won’t be sent to the campaign just yet. You'll be able to see when cleansing is finished on the dashboard.",
  },
  reviewFileName: {
    id: 'ListReview.reviewFileName',
    defaultMessage: 'File Name:',
  },
  reviewLeadType: {
    id: 'ListReview.reviewLeadType',
    defaultMessage: 'Lead Type:',
  },
  reviewCompany: {
    id: 'ListReview.reviewCompany',
    defaultMessage: 'Company:',
  },
  reviewCampaign: {
    id: 'ListReview.reviewCampaign',
    defaultMessage: 'Campaign:',
  },
  reviewColumnsMapped: {
    id: 'ListReview.reviewColumnsMapped',
    defaultMessage: 'Columns Mapped:',
  },
  reviewHangTight: {
    id: 'ListReview.reviewHangTight',
    defaultMessage: 'Hang tight while your list is importing…',
  },
});
