import { defineMessages } from 'react-intl';

export default defineMessages({
  hangTight: {
    id: 'hangTight',
    defaultMessage: 'Hang tight while your list is importing…',
  },
  newLeadListErrorNotification: {
    id: 'newLeadListErrorNotification',
    defaultMessage: "We can't seem to upload the list at this time.",
  },
  newLeadListSuccessNotification: {
    id: 'newLeadListSuccessNotification',
    defaultMessage: "I'll take it from here -Arman",
  },
  genLeadListSignedURLErrorNotification: {
    id: 'genLeadListSignedURLErrorNotification',
    defaultMessage:
      "We can't seem to get a connection with google to upload your list. Try again in a sec.",
  },
});
