import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FC } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

/**
 * Provide consistent layout and spacing for groups of buttons
 */
export const ButtonList: FC<ButtonListProps> = ({ children, className }) => {
  const classes = useStyles();

  return <div className={clsx(classes.root, className)}>{children}</div>;
};

interface ButtonListProps {
  className?: string;
}

export default ButtonList;
