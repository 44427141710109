import { defineMessages } from 'react-intl';

export default defineMessages({
  noResultsPrimary: {
    id: 'NoResults.primaryMessage',
    defaultMessage: `No results`,
  },
  noResultsSecondary: {
    id: 'NoResults.secondaryMessage',
    defaultMessage: 'The applied filters‍ did not return any results',
  },
});
