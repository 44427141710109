import { gql } from '@apollo/client';

export default gql`
  mutation CancelImport($id: Int!) {
    cancelLeadListImport(id: $id) {
      id
      status
      deleted
      name
      originalFilename
      sentToCampaign
      created
      totalRows
      estimatedTotalRows
      flagged
      rejected
      uploadedByFk
      uploadedBy {
        id
        firstName
        lastName
        email
      }
      companyFk
      company {
        id
        companyName
      }
      campaignFk
      campaign {
        id
        campaignName
      }
    }
  }
`;
