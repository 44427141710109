import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import messages from '../messages';
import { FormattedNumber, useIntl } from 'react-intl';
import { UserAccess_userAccess_organizations } from 'types/apollo/UserAccess';
import { TooltipBase } from 'components/TooltipBase';
import { LeadListTypeIcon } from 'components/LeadListTypeIcon/LeadListTypeIcon';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { ListType } from 'types/apollo/globalTypes';

interface LeadListReviewProps {
  leads: number;
  fileName: string;
  company: UserAccess_userAccess_organizations;
  campaign?: string;
  listType: ListType;
}

const useStyles = makeStyles((theme) => ({
  mainText: {
    fontWeight: 400,
    marginBottom: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  mappedItemsCategoryText: {
    paddingLeft: theme.spacing(1),
  },
}));

export const LeadListReview: FC<LeadListReviewProps> = ({
  leads,
  fileName,
  listType,
  company,
  campaign,
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  let headingMessage: { id: string; defaultMessage: string };
  switch (listType) {
    case ListType.PROSPECTIVE:
      headingMessage = messages.reviewLeadNumberImportedNew;
      break;
    case ListType.CLOSE:
      headingMessage = messages.reviewLeadNumberImportedClose;
      break;
    case ListType.DNC:
      headingMessage = messages.reviewLeadNumberImportedDnc;
      break;
    case ListType.ENDCONTACT:
      headingMessage = messages.reviewLeadNumberImportedEndContact;
      break;
  }
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h2" className={classes.mainText}>
        <Typography variant="h2" component="span">
          <FormattedNumber value={leads} />
        </Typography>{' '}
        {formatMessage(headingMessage)}
      </Typography>

      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={4}>
          <Typography color="textSecondary" className={classes.mappedItemsCategoryText}>
            {formatMessage(messages.reviewFileName)}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <TooltipBase title={fileName}>
            <Typography noWrap>{fileName}</Typography>
          </TooltipBase>
        </Grid>

        <Grid item xs={4}>
          <Typography color="textSecondary" className={classes.mappedItemsCategoryText}>
            {formatMessage(messages.reviewLeadType)}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <LeadListTypeIcon listType={listType} withLabel />
        </Grid>

        <Grid item xs={4}>
          <Typography color="textSecondary" className={classes.mappedItemsCategoryText}>
            {formatMessage(messages.reviewCompany)}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography noWrap>{company.companyName}</Typography>
        </Grid>

        {!!campaign && (
          <>
            <Grid item xs={4}>
              <Typography color="textSecondary" className={classes.mappedItemsCategoryText}>
                {formatMessage(messages.reviewCampaign)}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography noWrap>{campaign}</Typography>
            </Grid>
          </>
        )}

        <Grid item xs={4}>
          <Typography color="textSecondary" className={classes.mappedItemsCategoryText}>
            {formatMessage(messages.reviewColumnsMapped)}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <CheckCircleIcon style={{ width: '20px', height: '20px', color: '#00C389' }} />
        </Grid>
      </Grid>

      {listType === ListType.PROSPECTIVE && (
        <Typography variant="body1" style={{ marginTop: '16px', lineHeight: '1.75' }}>
          {formatMessage(messages.reviewClarification)}
        </Typography>
      )}
    </Box>
  );
};
