import { useLazyQuery } from '@apollo/client';
import React, { FC, useCallback } from 'react';
import { BasicView } from 'components/layout';
import { SORT_DIRECTION } from 'components/DataTable/constants';
import { LeadListsTable } from './components/LeadListsTable';
import { Table } from 'components/Table';
import { Pagination } from 'types/apollo/globalTypes';
import { GetLeadLists, GetLeadListsVariables } from 'types/apollo/GetLeadLists';
import { GET_LEAD_LIST } from './getLeadListPage.graphql';
import messages from './messages';
import { PageHeading } from 'components/PageHeading';
import { RouteComponentProps } from 'react-router-dom';

export const LeadListsView: FC<RouteComponentProps> = ({ history }) => {
  const [getLeadList, queryResult] = useLazyQuery<GetLeadLists, GetLeadListsVariables>(
    GET_LEAD_LIST,
    { fetchPolicy: 'cache-and-network' },
  );

  const handleChange = useCallback(
    (pagination: Pagination) => {
      getLeadList({ variables: { pagination } });
    },
    [getLeadList],
  );

  return (
    <BasicView>
      <PageHeading onBackClick={history.goBack} />

      <Table
        pageInfo={queryResult.data?.getLeadLists?.pageInfo}
        defaultSortDirection={SORT_DIRECTION.DESC}
        defaultSortField="created"
        loadData={handleChange}
        pageTitleMessage={messages.viewTitle}
        loading={queryResult.loading}
        called={queryResult.called}
        error={!!queryResult.error}
        items={queryResult.data?.getLeadLists.items || []}
        tableComponent={LeadListsTable}
      />
    </BasicView>
  );
};
