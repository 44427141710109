import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { FC } from 'react';

const useArrowStyles = makeStyles((theme) => ({
  container: {
    opacity: 0.01,
    transition: 'opacity 0.2s',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: `0 ${theme.spacing(3)}px`,
    border: 'none',
    backgroundColor: 'rgba(0,0,0,0)',
    outline: 'none',
    cursor: 'pointer',
    '&:hover': {
      opacity: 1,
    },
  },
  chevronContainer: {
    width: '32px',
    height: '32px',
    borderRadius: '32px',
    backgroundColor: 'rgb(244, 248, 250)',
    color: theme.palette.grey['500'],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export const Arrow: FC<{ direction: 'right' | 'left'; onClick: () => void }> = ({
  direction,
  onClick,
}) => {
  const classes = useArrowStyles();
  return (
    <button className={classes.container} onClick={onClick}>
      <div className={classes.chevronContainer}>
        {direction === 'right' && <ChevronRightIcon color={'inherit'} />}
        {direction === 'left' && <ChevronLeftIcon color={'inherit'} />}
      </div>
    </button>
  );
};
