import { defineMessages } from 'react-intl';

export default defineMessages({
  underConstructionPrimary: {
    id: 'UnderConstruction.primaryMessage',
    defaultMessage: '{title} is under construction',
  },
  underConstructionSecondary: {
    id: 'UnderConstruction.secondaryMessage',
    defaultMessage: 'Check back soon for an amazing experience.',
  },
});
