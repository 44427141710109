import { defineMessages } from 'react-intl';

export default defineMessages({
  errorStatePrimary: {
    id: 'ErrorState.primaryMessage',
    defaultMessage: 'Oops... an error occurred',
  },
  errorStateSecondary: {
    id: 'ErrorState.secondaryMessage',
    defaultMessage: 'Check the logs for more information.',
  },
});
