import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { ChangeEvent, useCallback } from 'react';
import { SortDirection } from 'types/apollo/globalTypes';
import { PageInfo } from '../../types';
import { TableMinimalPagination } from './TableMinimalPagination';
import { Column } from './dataTable.types';
import DataTableHeaderCell from './DataTableHeaderCell';
import { DataTableRow } from './DataTableRow';
import { MessageDescriptor, useIntl } from 'react-intl';

export interface DataTableContainerProps<T> {
  ariaLabel?: string;
  /** Column configuration **/
  columns: Column<T>[];
  /** Handle page change event **/
  onChangePage?: (page: number) => void;
  /** Handle row per page change event **/
  onChangeRowsPerPage?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onRowClick?: (row: T) => void;
  /** Handle sort event **/
  onSort?: (values: [string, SortDirection]) => void;
  /** Page of data **/
  items: Array<T>;
  pageInfo?: PageInfo;
  sortDirection?: SortDirection;
  sortField?: string;
  title?: MessageDescriptor;
}

/**
 * A paginated DataTable for displaying, sorting, selecting, and actioning on tabulated data
 *
 * @visibleName DataTable
 */
export function DataTableContainer<T>({
  ariaLabel,
  columns,
  onChangePage,
  onChangeRowsPerPage,
  onRowClick,
  onSort,
  items,
  pageInfo,
  sortDirection,
  sortField,
  title,
}: DataTableContainerProps<T>): JSX.Element {
  const { formatMessage } = useIntl();
  // Material UI's TablePagination uses 0-based paging. The rest of the view layer
  // and API uses 1-based paging
  const handleChangePage = useCallback(
    (event, page) => {
      onChangePage && onChangePage(page + 1);
    },
    [onChangePage],
  );

  return (
    <Box>
      {!!title && (
        <Box py={3} pl={3}>
          <Typography variant="h2" style={{ lineHeight: '1.75rem' }}>
            {formatMessage(title)}
          </Typography>
        </Box>
      )}
      <TableContainer>
        {!!(items && items.length) && (
          <>
            <Box pl={2} pr={2} pb={2}>
              <Table aria-label={ariaLabel} style={{ width: '100%', minWidth: '100%' }}>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <DataTableHeaderCell
                        column={column}
                        onSort={onSort}
                        key={column.dataKey}
                        sortField={sortField}
                        sortDirection={
                          sortField === (column.sortKey || column.dataKey)
                            ? sortDirection
                            : undefined
                        }
                      />
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.map((row: T) => (
                    <DataTableRow
                      columns={columns}
                      key={row['id']}
                      row={row}
                      onClick={onRowClick}
                    />
                  ))}
                </TableBody>
              </Table>
            </Box>
            {!!pageInfo && (
              <>
                <Divider />
                <Box padding="12px">
                  <TableMinimalPagination pageInfo={pageInfo} onChangePage={handleChangePage} />
                </Box>
              </>
            )}
          </>
        )}
      </TableContainer>
    </Box>
  );
}

export default DataTableContainer;
