import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { NotFoundImage } from 'images';
import messages from './messages';
import { PageEmptyState } from '../components/PageEmptyState';

export const PageNotFound: FC<{}> = (props) => (
  <PageEmptyState
    image={NotFoundImage}
    primary={<FormattedMessage {...messages.notFoundPrimary} />}
    secondary={<FormattedMessage {...messages.notFoundSecondary} />}
    {...props}
  />
);
