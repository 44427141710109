import { FC } from 'react';
import { useIntl } from 'react-intl';
import { GetLeadLists_getLeadLists_items } from 'types/apollo/GetLeadLists';
import { DataTable, DataTableContainerProps } from 'components/DataTable';
import { LeadListColumns } from './columns';
import messages from './messages';
import { useHistory } from 'react-router';
import routes from 'routes';

export const LeadListsTable: FC<LeadListsTableProps> = (props) => {
  const { formatMessage } = useIntl();
  const history = useHistory();

  const onRowClick = (row: GetLeadLists_getLeadLists_items) => {
    history.push(routes.leadList(row));
  };

  return (
    <DataTable
      ariaLabel={formatMessage(messages.ariaLabel)}
      columns={LeadListColumns}
      onRowClick={onRowClick}
      {...props}
    />
  );
};

type LeadListsTableProps = Omit<
  DataTableContainerProps<GetLeadLists_getLeadLists_items>,
  'columns'
>;
