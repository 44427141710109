import { defineMessages } from 'react-intl';

export default defineMessages({
  subheaderCampaignManagement: {
    id: 'AppNavDrawerContent.subheader.campaignManagement',
    defaultMessage: 'Campaign Management',
  },
  subheaderCommunication: {
    id: 'AppNavDrawerContent.subheader.communication',
    defaultMessage: 'Communication',
  },
  subheaderConfiguration: {
    id: 'AppNavDrawerContent.subheader.configuration',
    defaultMessage: 'Configuration',
  },
});
