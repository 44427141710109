import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import ListSubheader from '@material-ui/core/ListSubheader';
import { makeStyles } from '@material-ui/core/styles';
import { FC, Fragment } from 'react';
import { AktifyLogoImage } from '../../../images';
import { AppNavDrawerListItem } from './AppNavDrawerListItem';
import sections, { Section } from './navDrawerConfiguration';
import { useAccessControl } from '../../../hooks/useAccessControl';
import { AppNavDrawerOrgAutocomplete } from './AppNavDrawerOrgAutocomplete';

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 'auto',
    width: '50%',
  },
  toolbar: theme.mixins.toolbar,
}));

const AppNavDrawerContentSection: FC<AppNavDrawerContentSectionProps> = ({
  items,
  onNavigate,
  subHeader,
}) => {
  const { protectFeature } = useAccessControl();

  return (
    <Fragment>
      <Divider />
      {subHeader && <ListSubheader inset>{subHeader}</ListSubheader>}
      {items.map((item) =>
        protectFeature(
          <AppNavDrawerListItem key={item.id} onClick={onNavigate} item={item} />,
          item.featureFlags,
        ),
      )}
    </Fragment>
  );
};

interface AppNavDrawerContentSectionProps extends Section {
  onNavigate: () => void;
}

const AppNavDrawerContent: FC<AppNavDrawerContentProps> = ({ behavior, onNavigate }) => {
  const { protectFeature } = useAccessControl();
  const classes = useStyles();

  return (
    <>
      <Box className={classes.toolbar}>
        <Box alignItems="center" display="flex" justifyContent="center" height="100%">
          <AktifyLogoImage className={classes.logo} />
        </Box>
      </Box>
      <AppNavDrawerOrgAutocomplete behavior={behavior} />
      {sections.map((section) =>
        protectFeature(
          <AppNavDrawerContentSection key={section.id} onNavigate={onNavigate} {...section} />,
          section.featureFlags,
        ),
      )}
    </>
  );
};

interface AppNavDrawerContentProps {
  behavior: string;
  onNavigate: () => void;
}

export default AppNavDrawerContent;
