import { defineMessages } from 'react-intl';

export default defineMessages({
  noRecordsPrimary: {
    id: 'NoRecords.primaryMessage',
    defaultMessage: `No records`,
  },
  noRecordsSecondary: {
    id: 'NoRecords.secondaryMessage',
    defaultMessage: 'There is nothing here‍',
  },
});
