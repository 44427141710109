import { routeFnFactory, RouteFactory } from './utils/routeUtils';

const routes = {
  dashboard: '/',
  leadList: routeFnFactory('/lead-lists/:id'),
  leadLists: '/lead-lists',
  leadListCreate: '/lead-list-import',
  loginCallback: '/login/callback',
  workflowUploadLeadList: routeFnFactory('/campaigns/:campaignId/uploadLeadList'),
  // Unused:
  account: '/account',
  campaignDetails: routeFnFactory('/campaigns/:id'),
  campaignLeadLists: routeFnFactory('/campaigns/:id/lead-lists'),
  campaignList: '/campaigns',
  companyDetails: routeFnFactory('/companies/:id'),
  dncComplainers: '/dnc_litigators',
  dncLitigators: '/dnc_litigators',
  dncs: '/dncs',
  organizationUsers: '/organization/users',
  phoneLookups: '/phone_lookups',
  phoneNumbers: '/phone_numbers',
};

export type Route = string | RouteFactory;

export default routes;
