import { defineMessages } from 'react-intl';

export default defineMessages({
  deleteAriaLabel: {
    id: 'action.delete.ariaLabel',
    defaultMessage: 'delete',
  },
  deleteTooltip: {
    id: 'action.delete.tooltip',
    defaultMessage: 'Delete',
  },
});
