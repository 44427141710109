/**
 * Permissions system is currently handled by Auth0. Permissions are created in
 * the form of `<Action>:<Feature>:<Scope?>`
 *
 * Currently in Auth0
 *
 * ** 'create:user:organization'
 * ** 'list:user:organization'
 * ** 'list:campaign:organization'
 * ** 'list:dnc:organization'
 * ** 'bulk_create:dnc:organization'
 * ** 'delete:dnc:organization'
 * ** 'read:campaign:organization'
 * ** 'list:lead_list:organization'
 * ** 'create:lead_list:organization'
 * ** 'read:lead_list:organization'
 */
export enum Permission {
  LIST_USERS = 'list:user:organization',
  LIST_CAMPAIGN_ORGANIZATION = 'list:campaign:organization',
  READ_CAMPAIGN_ORGANIZATION = 'read:campaign:organization',
  LIST_PHONE_NUMBER = 'list:phone_number',
  LIST_PHONE_LOOKUP = 'list:phone_lookup',
  DELETE_DNC_ORGANIZATION = 'delete:dnc:organization',
  LIST_DNC_ORGANIZATION = 'list:dnc:organization',
  LIST_DNC_COMPLAINER = 'list:dnc_complainer',
  LIST_DNC_LITIGATOR = 'list:dnc_litigator',
  LIST_LEAD_LISTS_ORGANIZATION = 'list:lead_list:organization',
}

export default Permission;
