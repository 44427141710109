import { defineMessages } from 'react-intl';

export default defineMessages({
  clearSelectionAriaLabel: {
    id: 'action.clearSelection.ariaLabel',
    defaultMessage: 'clear selection',
  },
  clearSelectionTooltip: {
    id: 'action.clearSelection.tooltip',
    defaultMessage: 'Clear Selection',
  },
});
