import { makeStyles } from '@material-ui/core/styles';
import { useStateMachine } from 'little-state-machine';
import React, { FC, useEffect, useState } from 'react';
import { FileDropzone } from 'components/FileDropzone';
import { PageHeading } from 'components/PageHeading';
import { Paper } from 'components/Paper';
import { useIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { Nav } from '../Nav';
import { StepContainer } from '../StepContainer';
import messages from './messages';

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    width: '100%',
    padding: theme.spacing(3),
  },
}));

function updateFile(state, file: File | null) {
  return {
    ...state,
    leadListCreate: {
      ...state.leadListCreate,
      file,
    },
  };
}

export const StepSelectFile: FC<RouteComponentProps> = ({ history }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const {
    actions,
    state: { leadListCreate },
  } = useStateMachine({ updateFile });
  const [goGreen, setGoGreen] = useState(false);
  const [goRed, setGoRed] = useState(false);

  const nextStep = () => {
    setGoGreen(true);
    setTimeout(() => {
      history.push(`/lead-list-import/select-campaign`);
    }, 1000);
  };

  const markError = () => {
    setGoRed(true);
    setTimeout(() => {
      setGoRed(false);
    }, 5000);
  };

  const selectFile = (file: File) => {
    if (!file) return;
    actions.updateFile(file);
    if (!leadListCreate.hasSeenFileUpload && leadListCreate.file?.name) {
      nextStep();
    }
  };

  useEffect(() => {
    if (
      !leadListCreate.hasSeenFileUpload &&
      leadListCreate.file?.name &&
      !leadListCreate.fileProcessingError &&
      leadListCreate.fileProcessed
    ) {
      nextStep();
    } else if (leadListCreate.fileProcessingError) {
      markError();
    }
  }, [
    leadListCreate.hasSeenFileUpload,
    leadListCreate.file?.name,
    leadListCreate.fileProcessingError,
    leadListCreate.fileProcessed,
  ]);

  return (
    <StepContainer>
      <PageHeading title={formatMessage(messages.uploadTitle)} />

      <Paper
        variant="outlined"
        shade={goGreen ? 'success' : goRed ? 'error' : undefined}
        className={classes.paperContainer}
      >
        <FileDropzone
          selectedFile={leadListCreate.file}
          onDrop={(acceptedFiles, fileRejections) => {
            if (acceptedFiles[0]) {
              selectFile(acceptedFiles[0]);
            } else {
              console.error('Error getting file');
            }
          }}
          allowedFileTypes=".csv"
        />
      </Paper>
      <Nav
        onBackClick={history.goBack}
        onForwardClick={leadListCreate.hasSeenFileUpload ? nextStep : undefined}
        disableBack={goGreen}
        disableForward={goGreen}
      />
    </StepContainer>
  );
};
