import Box from '@material-ui/core/Box';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { OrganizationMembershipImage } from 'images';
import messages from './messages';
import { PageEmptyState } from '../components/PageEmptyState';

export const PageRequiresOrganizationMembership: FC<{}> = ({ ...props }) => {
  return (
    <Box alignItems="center" display="flex" height="100%" justifyContent="center" width="100%">
      <PageEmptyState
        image={OrganizationMembershipImage}
        primary={<FormattedMessage {...messages.orgMembershipPrimary} />}
        secondary={<FormattedMessage {...messages.orgMembershipSecondary} />}
        {...props}
      />
    </Box>
  );
};

export default PageRequiresOrganizationMembership;
