import makeStyles from '@material-ui/core/styles/makeStyles';
import { Children, FC } from 'react';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  root: {
    '& >*': {
      marginLeft: theme.spacing(2),
    },
  },
}));

export const AppHeaderActions: FC<{}> = ({ children }) => {
  const classes = useStyles();

  // Render nothing if no children are provided
  if (!Children.count(children)) {
    return null;
  }

  return <Box className={classes.root}>{children}</Box>;
};
