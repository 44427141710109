import { makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';

interface DotProps {
  active?: boolean;
}

const useStyles = makeStyles((theme) => ({
  dot: {
    width: '8px',
    height: '8px',
    borderRadius: '8px',
    margin: '0 6px',
    backgroundColor: ({ active }: DotProps) =>
      active ? theme.palette.grey[700] : theme.palette.grey[400],
  },
}));

export const Dot: FC<DotProps> = ({ active }) => {
  const classes = useStyles({ active });
  return <div className={classes.dot} />;
};
