import Typography from '@material-ui/core/Typography';
import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2),
  },
}));

export const ConfirmModalTitle: FC<{}> = ({ children }) => {
  const classes = useStyles();

  return (
    <Typography variant="h2" className={classes.title}>
      {children}
    </Typography>
  );
};
