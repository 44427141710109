import { defineMessages } from 'react-intl';

export default defineMessages({
  orgMembershipPrimary: {
    id: 'RequiresOrganizationMembership.primaryMessage',
    defaultMessage: `You have not been granted access to any organizations`,
  },
  orgMembershipSecondary: {
    id: 'RequiresOrganizationMembership.secondaryMessage',
    defaultMessage: 'Contact an administrator in your organization to request access',
  },
});
