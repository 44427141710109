import { FC } from 'react';
import { Label } from 'components/Label';

export const LEAD_LIST_STATUS_MAPPING = {
  UNPROCESSED: 0,
  PROCESSED: 1,
  PROCESSING: 2,
  ERRORS: 3,
  _SENT: 200,
  _CANCELED: 201,
};

export const LEAD_LIST_STATUS_MAPPING_TEXT = {
  [LEAD_LIST_STATUS_MAPPING.UNPROCESSED]: 'Queued',
  [LEAD_LIST_STATUS_MAPPING.PROCESSED]: 'Cleansed',
  [LEAD_LIST_STATUS_MAPPING.PROCESSING]: 'Cleansing',
  [LEAD_LIST_STATUS_MAPPING.ERRORS]: 'Errors',
  [LEAD_LIST_STATUS_MAPPING._SENT]: 'Sent',
  [LEAD_LIST_STATUS_MAPPING._CANCELED]: 'Canceled',
};

export const getShadeColorForLeadList = (
  status: number,
): 'warning' | 'success' | 'error' | 'info' => {
  if (
    status === LEAD_LIST_STATUS_MAPPING.UNPROCESSED ||
    status === LEAD_LIST_STATUS_MAPPING.PROCESSING
  ) {
    return 'warning';
  }
  if (status === LEAD_LIST_STATUS_MAPPING.PROCESSED) {
    return 'success';
  }
  if (status === LEAD_LIST_STATUS_MAPPING.ERRORS) {
    return 'error';
  }
  return 'info';
};

interface LeadListStatusLabelProps {
  status?: number;
  sentToCampaign?: string;
  deleted?: string;
}

export const LeadListStatusLabel: FC<LeadListStatusLabelProps> = ({
  status,
  deleted,
  sentToCampaign,
}) => {
  const mappingStatus = deleted
    ? LEAD_LIST_STATUS_MAPPING._CANCELED
    : sentToCampaign
    ? LEAD_LIST_STATUS_MAPPING._SENT
    : status
    ? status
    : 0;
  return (
    <Label color={getShadeColorForLeadList(mappingStatus)} style={{ maxWidth: '84px' }}>
      {LEAD_LIST_STATUS_MAPPING_TEXT[mappingStatus]}
    </Label>
  );
};
