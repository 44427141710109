import { useQuery } from '@apollo/client';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import React, { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';
import { AppHeader } from 'components/layout';
import messages from './messages';
import routes from 'routes';
import { LeadListCard } from './components/LeadListCard';
import GET_LEAD_LISTS_FOR_DASHBOARD from './getLeadListsForDashboard.graphql';
import { GetLeadListsForDashboard } from 'types/apollo/GetLeadListsForDashboard';
import { Carousel } from './components/Carousel';
import { ScreenFileDropzone } from './components/ScreenDropZone';
import { LEAD_LIST_PROCESSING_STATUS } from 'components/leadListStatus';

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  root: {
    height: '100vh',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  viewAllLink: {
    alignItems: 'center',
    color: theme.palette.grey[900],
    display: 'flex',
    fontSize: '0.85rem',
    fontWeight: 'bold',
    marginRight: '-8px', // Offset chevron padding
    textDecoration: 'none',
    padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
    borderRadius: '8px',
    transition: 'background-color 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
}));

export const DashboardView: FC<RouteComponentProps> = ({ history }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { loading, data, startPolling, stopPolling } = useQuery<GetLeadListsForDashboard>(
    GET_LEAD_LISTS_FOR_DASHBOARD,
    {
      fetchPolicy: 'network-only',
    },
  );

  const selectFile = (file: File) => {
    history.push(routes.leadListCreate, { file });
  };

  useEffect(() => {
    const idx = data?.getLeadLists?.items.findIndex((item) => {
      return (
        item.status === LEAD_LIST_PROCESSING_STATUS.UNPROCESSED ||
        item.status === LEAD_LIST_PROCESSING_STATUS.PROCESSING
      );
    });
    if (idx !== undefined && idx >= 0) {
      startPolling(5000);
    } else {
      stopPolling();
    }
  }, [data?.getLeadLists.items]);

  return (
    <>
      <AppHeader />
      <div className={classes.root}>
        <div className={classes.appBarSpacer} />

        <ScreenFileDropzone
          onDrop={(acceptedFiles, fileRejections) => {
            if (acceptedFiles[0]) {
              selectFile(acceptedFiles[0]);
            } else {
              console.error('Error getting file');
            }
          }}
        />

        <Box
          display="flex"
          flexDirection="column"
          flex="1"
          justifyContent="space-between"
          alignItems="center"
          pb={4}
        >
          <Carousel
            items={
              data?.getLeadLists.items
                .filter((list) => !list.deleted && !list.sentToCampaign)
                // @ts-ignore
                .sort((a, b) => new Date(b.created) - new Date(a.created)) || []
            }
            renderer={(item) => <LeadListCard leadList={item} />}
            loading={loading}
          />

          <Box zIndex={1}>
            <Link to={routes.leadLists} className={classes.viewAllLink}>
              {formatMessage(messages.viewAllLink)} <ChevronRightIcon />
            </Link>
          </Box>
        </Box>
      </div>
    </>
  );
};
