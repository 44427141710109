import React, { FC } from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  buttons: {
    '& button:not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
  },
}));

interface ConfirmModalActionsProps {
  onClose: () => void;
  onConfirm: () => void;
  cancelText?: string;
  confirmText?: string;
}

export const ConfirmModalActions: FC<ConfirmModalActionsProps> = ({
  onClose,
  onConfirm,
  cancelText,
  confirmText,
}) => {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="row" justifyContent="flex-end" className={classes.buttons}>
      <Button variant="text" onClick={onClose} size="small">
        {cancelText || 'Cancel'}
      </Button>
      <Button variant="contained" color="primary" onClick={onConfirm} size="small">
        {confirmText || 'Confirm'}
      </Button>
    </Box>
  );
};
