import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from 'components/IconButton';
import { FC } from 'react';

interface PageHeadingProps {
  title?: string;
  onBackClick?: () => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    marginBottom: '30px',
    minHeight: '18px',
    position: 'relative',
  },
  backButton: {
    position: 'absolute',
    left: 0,
  },
}));

export const PageHeading: FC<PageHeadingProps> = ({ title, onBackClick }) => {
  const classes = useStyles({ title, onBackClick });

  return (
    <div className={classes.container}>
      {!!onBackClick && (
        <IconButton ariaLabel="Go back" onClick={onBackClick} className={classes.backButton}>
          <ArrowBackIcon />
        </IconButton>
      )}

      <Typography variant="h2" align="center">
        {title}
      </Typography>
    </div>
  );
};
