import { defineMessages } from 'react-intl';

export default defineMessages({
  continueButton: {
    id: 'FileDropzone.continueButton',
    defaultMessage: 'Continue',
  },
  instrucitonDragAndDrop: {
    id: 'FileDropzone.instrucitonDragAndDrop',
    defaultMessage: 'Drag and drop a CSV',
  },
  instructionOr: {
    id: 'FileDropzone.instructionOr',
    defaultMessage: 'or',
  },
  instructionBrowse: {
    id: 'FileDropzone.instructionBrowse',
    defaultMessage: 'Browse',
  },
});
