import Snackbar, { SnackbarProps } from '@material-ui/core/Snackbar';
import React, { FC, ReactNode } from 'react';
import { DismissIconButton } from 'components/IconButtons';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Severity } from './notificationContext';
import CheckIcon from '@material-ui/icons/Check';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

interface NotificationProps extends SnackbarProps {
  message?: ReactNode;
  onClose: () => void;
  severity: Severity;
}

const useStyles = makeStyles((theme) => ({
  notificationContainer: {
    boxShadow: '0px 8px 16px #131A3414',
  },
  iconContainer: {
    width: '42px',
    height: '42px',
    backgroundColor: ({ severity }: { severity: Severity }) => theme.palette[severity][100],
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconCircle: {
    width: '24px',
    height: '24px',
    borderRadius: '24px',
    backgroundColor: ({ severity }: { severity: Severity }) => theme.palette[severity].main,
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  message: {
    margin: `0 ${theme.spacing(2)}px`,
    minWidth: '200px',
  },
}));

export const Notification: FC<NotificationProps> = ({ message, onClose, severity, ...props }) => {
  const classes = useStyles({ severity });
  return (
    <Snackbar
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      autoHideDuration={6000}
      onClose={onClose}
      {...props}
    >
      <Box
        display="flex"
        bgcolor="#FFF"
        px={2}
        py="10px"
        flexDirection="row"
        alignItems="center"
        borderRadius="8px"
        className={classes.notificationContainer}
      >
        <Box className={classes.iconContainer}>
          <Box className={classes.iconCircle}>
            {severity === 'success' && <CheckIcon fontSize="small" />}
            {severity === 'error' && (
              <ReportProblemOutlinedIcon style={{ width: '16px', height: '16px' }} />
            )}
          </Box>
        </Box>
        <Typography className={classes.message} variant="body1">
          {message}
        </Typography>
        <DismissIconButton size="small" onClick={onClose} />
      </Box>
    </Snackbar>
  );
};
