import { LEAD_LIST_PROCESSING_STATUS } from './constants';

/**
 * A lead list can be in one of the following status'
 *
 * - unprocessed
 * - processed
 * - processing
 * - errors
 * - sent
 * - deleted
 *
 * statusMessageFormatter will return the coresponding message.
 *
 * Reasoning: It was often that we would see many if else statements to determine
 * what message to show for a lead list. This just cleans all those up and helps us
 * not forget a status message.
 */
export function statusMessageFormatter<T>(
  { status, deleted, sentToCampaign }: LeadList,
  messages: Messages<T>,
): T {
  if (!!sentToCampaign) {
    return messages.sent;
  }
  if (!!deleted) {
    return messages.deleted;
  }
  if (status === LEAD_LIST_PROCESSING_STATUS.PROCESSED) {
    return messages.processed;
  }
  if (status === LEAD_LIST_PROCESSING_STATUS.PROCESSING) {
    return messages.processing;
  }
  if (status === LEAD_LIST_PROCESSING_STATUS.UNPROCESSED) {
    return messages.unprocessed;
  }
  if (status === LEAD_LIST_PROCESSING_STATUS.ERRORS) {
    return messages.errors;
  }

  return messages.unknown;
}

interface LeadList {
  status: number;
  deleted?: string | null;
  sentToCampaign?: string | null;
}

interface Messages<T> {
  deleted: T;
  errors: T;
  processed: T;
  processing: T;
  sent: T;
  unprocessed: T;
  unknown: T;
}
