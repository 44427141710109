import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';
import { FC, useCallback } from 'react';
import { usePageContext } from '../PageContent/pageContext';
import AppNavDrawerContent from './AppNavDrawerContent';

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('lg')]: {
      flexShrink: 0,
      width: `${theme.layout.drawerWidth}px`,
    },
  },
  drawerPaper: {
    width: theme.layout.drawerWidth,
  },
}));

export const AppNavDrawer: FC<{}> = () => {
  const classes = useStyles();
  const { navDrawerOpen, setNavDrawerOpen } = usePageContext();

  const handleNavDrawerClose = useCallback(() => {
    setNavDrawerOpen(false);
  }, [setNavDrawerOpen]);

  return (
    <nav className={classes.drawer}>
      <Hidden lgUp implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          id="nav-drawer"
          ModalProps={{ keepMounted: true }}
          open={navDrawerOpen}
          onClose={handleNavDrawerClose}
          variant="temporary"
        >
          <AppNavDrawerContent behavior={'responsive'} onNavigate={handleNavDrawerClose} />
        </Drawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          id="nav-drawer"
          open
          variant="permanent"
        >
          <AppNavDrawerContent behavior={'fixed'} onNavigate={handleNavDrawerClose} />
        </Drawer>
      </Hidden>
    </nav>
  );
};
