import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles((theme) => ({
  appBar: {
    width: '100%',
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.background.paper,
  },
  logo: {
    height: '13px',
    width: 'auto',
  },
  logoutButton: {
    marginRight: theme.spacing(0),
    color: theme.palette.grey[500],
  },
}));
