import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import { ReactNode } from 'react';

const useStyles = makeStyles((theme) => ({
  tableCell: {
    fontSize: '1rem',
  },
}));

export const DataTableCell = ({
  align = 'left',
  cellRenderer,
  data,
  style,
}: DataTableCellProps): JSX.Element => {
  const classes = useStyles();
  const content = cellRenderer(data);

  return (
    <TableCell align={align} style={style} className={classes.tableCell}>
      {content}
    </TableCell>
  );
};

interface DataTableCellProps {
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  cellRenderer: (data: any) => ReactNode;
  data: any;
  style?: object;
}

export default DataTableCell;
