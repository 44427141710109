import { FC } from 'react';
import MUIPaper, { PaperProps as MUIPaperProps } from '@material-ui/core/Paper';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
  shade?: 'success' | 'warning' | 'error' | 'default';
}

type PaperProps = MUIPaperProps & Props;

const useStyles = makeStyles((theme) => ({
  shade: ({ shade = 'success' }: Props) => ({
    boxShadow: `0px 0px 99px ${theme.palette[shade][100]}`,
    border: `1px solid ${theme.palette[shade][300]}`,
  }),
  container: {
    transition: 'box-shadow 0.7s, border 0.7s',
    border: `0.75px solid ${theme.palette.grey[200]}`,
  },
}));

export const Paper: FC<PaperProps> = ({ className, shade, variant = 'outlined', ...props }) => {
  if (shade === 'default') {
    shade = undefined;
  }
  const classes = useStyles({ shade });
  return (
    <MUIPaper
      className={clsx(classes.container, { [classes.shade]: shade }, className)}
      variant={variant}
      {...props}
    />
  );
};
