import React, { FC, ReactNode } from 'react';
import { DataTableCellEmptyValue, formattedNumberRenderer } from 'components/DataTable/renderers';
import { LEAD_LIST_PROCESSING_STATUS } from 'components/leadListStatus';
import { LeadListStatusLabel } from 'components/LeadListStatusLabel';

export const leadCountRenderer = (count: number | null): ReactNode => {
  if (count && Number.isInteger(count)) {
    return formattedNumberRenderer(count);
  }

  return <DataTableCellEmptyValue />;
};

export const processingStatusRenderer: FC<processingStatusRendererProps> = ({
  errors,
  ...props
}) => <LeadListStatusLabel {...props} />;

export interface processingStatusRendererProps {
  errors?: [];
  status: LEAD_LIST_PROCESSING_STATUS;
}
