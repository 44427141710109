const routeParamRegExp = /:[^/]+/g;

type RouteParam = { [key: string]: string };

const replaceRouteParam = (routeParams: RouteParam) => (match) => {
  const replacement = match.substring(1);
  return routeParams[replacement];
};

const replaceRouteParams = (pathTemplate: string, routeParams: RouteParam) =>
  pathTemplate.replace(routeParamRegExp, replaceRouteParam(routeParams));

const validateRouteParams = (replacements: string[], routeParams: RouteParam) => {
  replacements.forEach((replacement) => {
    const value = routeParams[replacement];

    if (!Number.isInteger(value) && (typeof value !== 'string' || value === '')) {
      throw new Error(
        `routeParam replacement ${replacement} must be a non-empty string or integer`,
      );
    }
  });
};

// TODO: update routeFnFactory to take generic of which keys are required
type RouteFactoryFunction = (params: object) => string;
export interface RouteFactory extends RouteFactoryFunction {
  pathTemplate: string;
}

/**
 * Returns a route function.
 *
 * Ex: If the defined path template is /users/:userId and the resolution function is invoked with
 *  a routeParams object of { userId: 'abc123' }, the function would return "/users/abc123".
 *
 * @param {string} pathTemplate
 * @returns {function(object): string} A route path resolution function
 */

export const routeFnFactory = (pathTemplate: string): RouteFactory => {
  const replacements = Array.from(pathTemplate.matchAll(routeParamRegExp)).map(([match]) =>
    match.substring(1),
  );

  const pathResolverFn = (routeParams) => {
    if (!routeParams) {
      return pathTemplate;
    }

    process.env.NODE_ENV === 'development' && validateRouteParams(replacements, routeParams);

    return replaceRouteParams(pathTemplate, routeParams);
  };

  const match = pathTemplate.match(/^([/:\-_a-z]+)/i);

  return Object.assign(pathResolverFn, { pathTemplate: match ? match[1] : '' });
};
