import Typography from '@material-ui/core/Typography';
import { FC } from 'react';

export const TextRenderer: FC<TextRendererProps> = ({ label }) => {
  return <Typography variant="inherit">{label}</Typography>;
};

export interface TextRendererProps {
  label: string;
}
