import {
  default as MuiIconButton,
  IconButtonProps as MUIIconButtonProps,
} from '@material-ui/core/IconButton';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import clsx from 'clsx';
import { FC, ReactNode } from 'react';

export interface IconButtonProps extends MUIIconButtonProps {
  ariaLabel: string;

  icon?: ReactNode;

  tooltipProps?: Omit<TooltipProps, 'children'>;
}

export const IconButton: FC<IconButtonProps> = ({
  ariaLabel,
  children,
  className,
  color,
  disabled,
  icon,
  tooltipProps,
  ...props
}) => {
  if (!(children || icon)) {
    throw new Error('Expected either `children` or `icon` prop to be provided.');
  }

  const button = (
    <MuiIconButton
      aria-label={ariaLabel}
      className={clsx(className)}
      color={color}
      disabled={disabled}
      {...props}
    >
      {children || icon}
    </MuiIconButton>
  );

  if (disabled || !tooltipProps?.title) {
    return button;
  }

  return (
    <Tooltip arrow enterDelay={300} {...tooltipProps}>
      {button}
    </Tooltip>
  );
};
