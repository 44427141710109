import Typography from '@material-ui/core/Typography';
import { FC } from 'react';
import { FormattedDate as FormattedDateIntl } from 'react-intl';

export const FormattedDate: FC<FormattedDateProps> = ({ value }) => {
  return (
    <Typography noWrap variant="inherit">
      <FormattedDateIntl day="2-digit" month="short" value={value} year="numeric" />
    </Typography>
  );
};

interface FormattedDateProps {
  value: string | number | Date;
}
