import { useLazyQuery } from '@apollo/client';
import { LoginCallback, useOktaAuth } from '@okta/okta-react';
import React, { FC, createContext, useEffect } from 'react';
import { Route } from 'react-router-dom';
// import { useLDClient } from 'launchdarkly-react-client-sdk';
import { UserAccess, UserAccess_userAccess_organizations } from 'types/apollo/UserAccess';
import { PageLoading, PageError, PageRequiresOrganizationMembership } from 'components/EmptyState';
import { BasicView } from 'components/layout';
import { USER_ACCESS } from './userAccess.graphql';
import routes from 'routes';

interface Context {
  loading: boolean;
  organizations: UserAccess_userAccess_organizations[];
  permissions: string[];
  id?: number;
}

export const AccessControl = createContext<Context>({
  loading: false,
  organizations: [],
  permissions: [],
  id: 0,
});

export const AccessControlProvider: FC<{}> = ({ children }) => {
  const { authState, oktaAuth } = useOktaAuth();
  const [getUser, { data, error, loading }] = useLazyQuery<UserAccess>(USER_ACCESS);
  // const ldClient = useLDClient();

  useEffect(() => {
    if (authState.isPending) {
      return;
    }
    if (authState.isAuthenticated) {
      getUser();
    } else {
      oktaAuth.signInWithRedirect();
    }
  }, [authState.isAuthenticated, authState.isPending]);

  if (error) {
    console.error(error);
    return <PageError />;
  }

  if (data?.userAccess) {
    const organizations = data.userAccess.organizations || [];

    /**
     * Launch Darkly: Set the user based on who is logged in. This allows
     * us to control feature flags per user.
     */
    // ldClient?.identify({ key: `${data.userAccess.id}` });

    if (authState.accessToken?.claims.org !== '*' && !authState.accessToken?.claims?.org?.length) {
      return (
        <BasicView>
          <PageRequiresOrganizationMembership />
        </BasicView>
      );
    }

    return (
      <AccessControl.Provider
        value={{
          loading,
          organizations: organizations,
          permissions: [],
          id: data?.userAccess.id,
        }}
      >
        {children}
      </AccessControl.Provider>
    );
  }

  return (
    <>
      <Route path={routes.loginCallback} component={LoginCallback} />
      <BasicView>
        <PageLoading />
      </BasicView>
    </>
  );
};
