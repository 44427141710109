import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { FC } from 'react';

const useStyles = makeStyles((theme) => ({
  categoryLabel: {
    marginRight: '2px',
  },
  type: {
    overflow: 'hidden',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    hyphens: 'auto',
  },
}));

interface InfoPointProps {
  category: string;
  info: string | React.ReactNode;
}

export const InfoPoint: FC<InfoPointProps> = ({ category, info }) => {
  const classes = useStyles();

  return (
    <Grid item container xs={12} alignItems="center">
      <Grid item xs={3}>
        <Typography
          color="textSecondary"
          component="span"
          variant="body1"
          className={classes.categoryLabel}
        >
          {category}:
        </Typography>
      </Grid>
      <Grid item xs={9}>
        {typeof info === 'string' ? (
          <Typography variant="body1" className={classes.type}>
            {info}
          </Typography>
        ) : (
          info
        )}
      </Grid>
    </Grid>
  );
};
