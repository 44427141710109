import { defineMessages } from 'react-intl';

export default defineMessages({
  notFoundPrimary: {
    id: 'NotFound.primaryMessage',
    defaultMessage: `Hmm... I couldn't find that`,
  },
  notFoundSecondary: {
    id: 'NotFound.secondaryMessage',
    defaultMessage: 'Check the URL to make sure you typed it correctly.',
  },
});
