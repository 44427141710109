import Box from '@material-ui/core/Box';
import React, { useRef, useState, useEffect, ReactNode } from 'react';
import { Arrow } from './components/Arrow';
import { Dot } from './components/Dot';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
interface CarouselProps<Item = { id: string | number }> {
  items: Array<Item>;
  renderer: (item: Item) => ReactNode;
  loading?: boolean;
}

const useStyles = makeStyles((theme) => ({
  container: {
    opacity: (props: { hasItems: boolean }) => (props.hasItems ? 1 : 0),
    transition: 'all 0.75s ease-in-out;',
  },
  contentContainer: {
    transform: (props: { hasItems: boolean }) => `translateY(${props.hasItems ? 0 : 18}px)`,
    transition: 'all 0.5s ease-in-out;',
  },
  content: {
    width: '530px',
    height: '290px',
    position: 'relative',
  },
}));

export function Carousel<Item>({ items, renderer, loading }: CarouselProps<Item>): JSX.Element {
  const classes = useStyles({ hasItems: !!items.length });
  const [currentPos, setCurrentPos] = useState(0);
  const [dir, setDir] = useState<'from-left' | 'from-right'>('from-left');
  const listLengthRef = useRef(0);
  const currentPosRef = useRef(currentPos);

  const getPrev = () => {
    const newPos =
      currentPosRef.current <= 0 ? listLengthRef.current - 1 : currentPosRef.current - 1;
    setDir('from-left');
    setCurrentPos(newPos);
    currentPosRef.current = newPos;
  };

  const getNext = () => {
    const newPos =
      currentPosRef.current === listLengthRef.current - 1 ? 0 : currentPosRef.current + 1;
    setDir('from-right');
    setCurrentPos(newPos);
    currentPosRef.current = newPos;
  };

  const handleKeyPress = (e) => {
    if (e.key === 'ArrowLeft') {
      getPrev();
    } else if (e.key === 'ArrowRight') {
      getNext();
    }
  };

  useEffect(() => {
    listLengthRef.current = items.length;
    // Check to make sure current position is within bounds if the items array changes
    if (currentPos && currentPos > items.length - 1) {
      setCurrentPos(items.length - 1);
      currentPosRef.current = items.length - 1;
    }
  }, [items, currentPos]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const currentItem = items[currentPos];

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      zIndex={1}
      className={classes.container}
    >
      <Box display="flex" flexDirection="row" className={classes.contentContainer}>
        {!!items.length && items.length > 1 && <Arrow direction="left" onClick={getPrev} />}

        {!loading && currentItem && !!items.length && (
          <Box className={clsx(classes.content, dir)}>
            <TransitionGroup>
              <CSSTransition key={currentItem['id']} timeout={300} classNames={'slide'}>
                <div style={{ position: 'absolute', width: '100%' }}>{renderer(currentItem)}</div>
              </CSSTransition>
            </TransitionGroup>
          </Box>
        )}

        {!!items.length && items.length > 1 && <Arrow direction="right" onClick={getNext} />}
      </Box>

      <Box display="flex" flexDirection="row" mt={4}>
        {!!items.length &&
          items.length > 1 &&
          items.map((i, idx) => <Dot key={i['id']} active={idx === currentPos} />)}
      </Box>
    </Box>
  );
}
