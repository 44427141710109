import { makeStyles } from '@material-ui/core/styles';
import { createStore, useStateMachine, GlobalState } from 'little-state-machine';
import React, { FC, useEffect, useState } from 'react';
import { Redirect, Route, RouteComponentProps, Switch, matchPath } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { BasicView } from 'components/layout';
import { StepSelectFile } from './components/StepSelectFile';
import { StepSelectLeadType } from './components/StepSelectLeadType';
import { StepMapColumns } from './components/StepMapColumns';
import { StepReview } from './components/StepReview';
import { StepImporting } from './components/StepImporting';
import { useNotifications } from 'components/notifications';

createStore({
  leadListCreate: {},
});

const STEPS = {
  fileUpload: 'file-upload',
  selectCampaign: 'select-campaign',
  mapColumns: 'map-columns',
  review: 'review',
  importing: 'importing',
};

const useStyles = makeStyles((theme) => ({
  transitionGroup: {
    width: '100%',
    position: 'relative',
  },
}));

function setLeadListCreate(state: GlobalState, payload: GlobalState['leadListCreate']) {
  return {
    ...state,
    leadListCreate: {
      ...state.leadListCreate,
      ...payload,
    },
  };
}

function clearLeadListCreate(state: GlobalState) {
  return {
    ...state,
    leadListCreate: {},
  };
}

export const LeadListCreateView: FC<RouteComponentProps<null, any, { file?: File }>> = ({
  history,
  location,
  match,
}) => {
  const classes = useStyles();
  const { actions, state } = useStateMachine({ setLeadListCreate, clearLeadListCreate });
  const { notifyError } = useNotifications();
  /**
   * If we have been given a file from the previous location, we'll put it on state.
   * If we have a file object on state, but its not a complete file, we want to clear it.
   * This happens if you were to refresh in the middle of the form flow.
   */
  useEffect(() => {
    if (location.state?.file !== undefined) {
      actions.setLeadListCreate({ file: location.state.file });
    } else if (!state.leadListCreate.file?.name) {
      actions.clearLeadListCreate(undefined);
    }
    return () => {
      actions.clearLeadListCreate(undefined);
    };
  }, []);

  useEffect(() => {
    if (state.leadListCreate?.file) {
      if (state.leadListCreate.file?.name) {
        parseFile(state.leadListCreate.file);
      }
    }
  }, [state.leadListCreate.file]);

  const parseFile = (file: File) => {
    if (!['text/csv', 'application/vnd.ms-excel'].includes(file.type)) {
      actions.setLeadListCreate({
        fileProcessed: false,
        fileProcessingError: true,
        file: null,
      });
      notifyError(
        "Hey, looks like you didn't provide a csv file. Please select a different file.",
        'fileTypeError',
      );
      return;
    }
    if (file.size < 10) {
      notifyError(
        "Hey, looks like the file you tried to upload doesn't have sufficient data. Please select a different file.",
        'fileTypeError',
      );
      actions.setLeadListCreate({
        fileProcessed: false,
        fileProcessingError: true,
        file: null,
      });
      return;
    }
    if (state.leadListCreate.file?.name && state.leadListCreate.file?.name.length > 256) {
      notifyError(
        'Hey, looks like the file you tried to upload has too long of a name. Please select a different file.',
        'fileTypeError',
      );
      actions.setLeadListCreate({
        fileProcessed: false,
        fileProcessingError: true,
        file: null,
      });
      return;
    }
    const reader = new FileReader();
    reader.onload = async (f: Event) => {
      const text = reader.result;
      // We should never get here, but according to ts text could also be an array buffer or null
      // so we'll just double check we are working with a string.
      if (!text || text instanceof ArrayBuffer) {
        return;
      }
      const csvHeader = text.split('\n', 1)[0];
      const fileHeaders = csvHeader.split(',').reduce((acc: string[], cur) => {
        if (cur) {
          return [...acc, cur.trim()];
        }
        return acc;
      }, []);

      const match = text.match(/\r?\n/g);
      const estimatedTotalRows = match ? match.length : -1;
      actions.setLeadListCreate({
        fileProcessingError: false,
        fileProcessed: true,
        fileHeaders,
        mappings: {},
        estimatedTotalRows,
      });
    };
    reader.readAsText(file);
  };

  return (
    <BasicView
      maxWidth="sm"
      hideHeader={!!matchPath(location.pathname, { path: `${match.path}/${STEPS.importing}` })}
    >
      <TransitionGroup className={classes.transitionGroup}>
        <CSSTransition key={location.key} timeout={450} classNames="fade">
          <Switch location={location}>
            <Route exact path={`${match.path}`} component={StepSelectFile} />
            <Route path={`${match.path}/${STEPS.selectCampaign}`} component={StepSelectLeadType} />
            <Route path={`${match.path}/${STEPS.mapColumns}`} component={StepMapColumns} />
            <Route path={`${match.path}/${STEPS.review}`} component={StepReview} />
            <Route path={`${match.path}/${STEPS.importing}`} component={StepImporting} />
            <Redirect to={`${match.path}`} />;
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </BasicView>
  );
};
