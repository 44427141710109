import { defineMessages } from 'react-intl';

export default defineMessages({
  importLink: {
    id: 'DashboardView.importListLink',
    defaultMessage: 'Import a List',
  },
  viewAllLink: {
    id: 'DashboardView.viewAllLink',
    defaultMessage: 'View All Imports',
  },
});
