import Typography from '@material-ui/core/Typography';
import { FC } from 'react';

export const EmptyStatePrimaryTypography: FC<{}> = ({ children }) => {
  return (
    <Typography align="center" component="h2" gutterBottom variant="h4">
      {children}
    </Typography>
  );
};

export default EmptyStatePrimaryTypography;
