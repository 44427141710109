import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paperContainer: {
    width: '100%',
    padding: theme.spacing(3),
  },
  fileName: {
    marginBottom: theme.spacing(4),
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: `${theme.spacing(4)}px ${theme.spacing(4)}px 0`,
  },
  select: {
    marginBottom: theme.spacing(4),
  },
  selectGreen: {
    color: theme.palette.success[500],
  },
  greenOnHover: {
    '&:hover': {
      color: theme.palette.success[500],
    },
  },
  selectOrange: {
    color: '#FFB81C',
  },
  orangeOnHover: {
    '&:hover': {
      color: '#FFB81C',
    },
  },
  selectRed: {
    color: theme.palette.error[500],
  },
  redOnHover: {
    '&:hover': {
      color: theme.palette.error[500],
    },
  },
  selectYellow: {
    color: theme.palette.warning[500],
  },
  yellowOnHover: {
    '&:hover': {
      color: theme.palette.warning[500],
    },
  },
}));
