import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { SortDirection } from 'types/apollo/globalTypes';
import { Column } from './dataTable.types';
import { getAriaSortDirection, reverseSortDirection } from './utils';

const mapSortDirection = (dir?: SortDirection): 'asc' | 'desc' | undefined => {
  switch (dir) {
    case SortDirection.ASC:
      return 'asc';
    case SortDirection.DESC:
      return 'desc';
    default:
      return;
  }
};

const useStyles = makeStyles((theme) => ({
  tableCellHeader: {
    fontSize: '1rem',
  },
}));

export function DataTableHeaderCell<T>({
  column,
  onSort,
  sortDirection = SortDirection.ASC,
  sortField,
}: DataTableHeaderCellProps<T>): JSX.Element {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { dataKey, enableSort, sortKey: sortKeyOverride } = column;
  const sortKey = sortKeyOverride || dataKey;

  const handleSort = useCallback(() => {
    onSort && onSort([sortKey, reverseSortDirection(sortDirection)]);
  }, [onSort, sortDirection, sortKey]);

  const label = formatMessage(column.label);

  const sd = mapSortDirection(sortDirection);
  return (
    <TableCell
      align={column.align || 'left'}
      aria-sort={getAriaSortDirection(sortDirection)}
      sortDirection={sd}
      style={column.style}
      className={classes.tableCellHeader}
    >
      {enableSort ? (
        <TableSortLabel active={column.dataKey === sortField} direction={sd} onClick={handleSort}>
          {label}
        </TableSortLabel>
      ) : (
        label
      )}
    </TableCell>
  );
}

interface DataTableHeaderCellProps<T> {
  column: Column<T>;
  onSort?: ([string, SortDirection]) => void;
  sortDirection?: SortDirection;
  sortField?: string;
}

export default DataTableHeaderCell;
