import { defineMessages } from 'react-intl';

export default defineMessages({
  clearSelectionButtonAriaLabel: {
    id: 'AppHeader.clearSelectionButton.ariaLabel',
    defaultMessage: 'clear selection',
  },
  filterButtonAriaLabel: {
    id: 'AppHeader.filterButton.ariaLabel',
    defaultMessage: 'open filter drawer',
  },
  logoutButtonAriaLabel: {
    id: 'AppHeader.logoutButton.ariaLabel',
    defaultMessage: 'Logout',
  },
  logoTitle: {
    id: 'AppHeader.logo.title',
    defaultMessage: 'Go Home',
  },
  menuButtonAriaLabel: {
    id: 'AppHeader.menuButton.ariaLabel',
    defaultMessage: 'open nav drawer',
  },
  numSelected: {
    id: 'AppHeader.title.numSelected',
    defaultMessage: '{count} selected',
  },
});
