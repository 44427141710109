import React, { FC, useState } from 'react';
import { Redirect, RouteComponentProps, Prompt } from 'react-router-dom';
import { useStateMachine } from 'little-state-machine';
import { PageHeading } from 'components/PageHeading';
import { Paper } from 'components/Paper';
import { useIntl } from 'react-intl';
import { StepContainer } from '../StepContainer';
import { LeadListReview } from '../LeadListReview';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import messages from './messages';
import { Nav } from '../Nav';
import {
  ConfirmModal,
  ConfirmModalTitle,
  ConfirmModalBody,
  ConfirmModalActions,
} from 'components/ConfirmModal';
import { LeadListTypeIcon } from 'components/LeadListTypeIcon';
import { ListType } from 'types/apollo/globalTypes';

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    width: '100%',
    padding: theme.spacing(3),
  },
  fileName: {
    marginBottom: theme.spacing(4),
  },
}));

export const StepReview: FC<RouteComponentProps> = ({ history, location, match }) => {
  const {
    state: { leadListCreate },
  } = useStateMachine();
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const [goGreen, setGoGreen] = useState(false);
  const [goBack, setGoBack] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  if (
    !leadListCreate.file ||
    !leadListCreate.company ||
    !leadListCreate.listType ||
    (leadListCreate.listType === ListType.PROSPECTIVE && !leadListCreate.campaign) ||
    !leadListCreate.mappings ||
    !leadListCreate.estimatedTotalRows
  ) {
    return <Redirect to={`/lead-list-import`} />;
  }

  const nextStep = () => {
    if (goGreen) {
      return;
    }
    setShowConfirmation(false);
    setGoGreen(true);
    setTimeout(() => {
      history.push(`/lead-list-import/importing`);
    }, 500);
  };

  const onImportClick = () => {
    if (leadListCreate.listType === ListType.PROSPECTIVE) {
      nextStep();
    } else if (
      leadListCreate.listType === ListType.CLOSE ||
      leadListCreate.listType === ListType.DNC ||
      leadListCreate.listType === ListType.ENDCONTACT
    ) {
      setShowConfirmation(true);
    }
  };
  let confirmType: string;
  switch (leadListCreate.listType) {
    case ListType.DNC:
      confirmType = 'DNC';
      break;
    case ListType.CLOSE:
      confirmType = 'Closed';
      break;
    case ListType.ENDCONTACT:
      confirmType = 'End Contact';
      break;
    default:
      confirmType = '';
      break;
  }

  return (
    <StepContainer>
      <PageHeading title={formatMessage(messages.reviewTitle)} />

      <Paper
        variant="outlined"
        className={classes.paperContainer}
        shade={goGreen ? 'success' : undefined}
      >
        <LeadListReview
          leads={leadListCreate.estimatedTotalRows}
          fileName={leadListCreate.file.name}
          listType={leadListCreate.listType}
          company={leadListCreate.company}
          campaign={leadListCreate.campaign?.campaignName || ''}
        />
      </Paper>

      <Box mt={4} width="50%">
        <Button variant="contained" color="primary" fullWidth onClick={onImportClick}>
          {formatMessage(messages.reviewImportList)}
        </Button>
      </Box>
      <Nav
        onBackClick={() => {
          setGoBack(true);
          history.goBack();
        }}
      />

      <ConfirmModal open={!!showConfirmation} onClose={() => setShowConfirmation(false)}>
        <ConfirmModalTitle>{`Ready to mark leads as ‘${confirmType}’`}</ConfirmModalTitle>
        <LeadListTypeIcon
          listType={leadListCreate.listType}
          style={{ position: 'absolute', top: '16px', right: '16px', fontSize: '28px' }}
        />
        <ConfirmModalBody>
          We’ll make sure not to send any messages to these contacts. You won’t be able to undo this
          action.
        </ConfirmModalBody>
        <ConfirmModalActions onClose={() => setShowConfirmation(false)} onConfirm={nextStep} />
      </ConfirmModal>
      <Prompt
        when={!goGreen && !goBack}
        message={(location) =>
          `You have not submitted this list yet, are you sure you want to leave and cancel this import?`
        }
      />
    </StepContainer>
  );
};
