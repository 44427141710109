import { gql } from '@apollo/client';

export default gql`
  mutation CreateLeadList($input: CreateLeadListInput!) {
    createLeadList(input: $input) {
      id
      companyFk
      campaignFk
      estimatedTotalRows
      name
      originalFilename
      columnMapping
      listType
    }
  }
`;
