import { gql } from '@apollo/client';

export const USER_ACCESS = gql`
  query UserAccess {
    userAccess {
      id
      organizations {
        id
        companyName
      }
    }
  }
`;
