import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from 'components/IconButton';
import { FC } from 'react';

interface NavProps {
  title?: string;
  onBackClick?: () => void;
  onForwardClick?: () => void;
  disableForward?: boolean;
  disableBack?: boolean;
}

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  arrowButton: {
    color: theme.palette.grey[500],
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
  },
}));

export const Nav: FC<NavProps> = ({
  title,
  onBackClick,
  onForwardClick,
  disableBack,
  disableForward,
}) => {
  const classes = useStyles();

  return (
    <Box display="flex" justifyContent="center" className={classes.container}>
      {!!onBackClick && (
        <IconButton
          ariaLabel="Go back"
          className={classes.arrowButton}
          onClick={onBackClick}
          disabled={disableBack}
        >
          <ArrowBackIcon />
        </IconButton>
      )}
      {!!onForwardClick && (
        <IconButton
          ariaLabel="Next"
          className={classes.arrowButton}
          onClick={onForwardClick}
          disabled={disableForward}
        >
          <ArrowForward />
        </IconButton>
      )}
    </Box>
  );
};
