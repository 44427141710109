import { gql } from '@apollo/client';

export const GET_LEAD_LIST = gql`
  query GetLeadList($leadListId: Int!) {
    getLeadList(id: $leadListId) {
      id
      status
      name
      originalFilename
      sentToCampaign
      created
      deleted
      totalRows
      estimatedTotalRows
      flagged
      listType
      rejected
      uploadedByFk
      uploadedBy {
        id
        firstName
        lastName
        email
      }
      companyFk
      company {
        id
        companyName
      }
      campaignFk
      campaign {
        id
        campaignName
      }
    }
  }
`;
