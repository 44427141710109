import { Route, Switch } from 'react-router-dom';
import { FC } from 'react';
import { Okta } from 'contexts/okta';
import { StateMachineProvider } from 'little-state-machine';
import { AppShell } from './components/layout';
import { NotificationProvider } from './components/notifications';
import { AccessControlProvider } from 'contexts/accessControl';
import routes from './routes';
import { PageNotFound } from 'components/EmptyState';
import { BasicView } from 'components/layout';
import { DashboardView } from 'views/Dashboard';
import { LeadListCreateView } from 'views/LeadListCreate';
import { LeadListsView } from 'views/LeadLists';
import { LeadListDetails } from 'views/LeadListDetails';

const App: FC<{}> = () => (
  <Okta>
    <AppShell>
      <AccessControlProvider>
        <NotificationProvider>
          <StateMachineProvider>
            <Switch>
              <Route exact path={routes.dashboard} component={DashboardView} />
              <Route exact path={routes.leadLists} component={LeadListsView} />
              <Route path={routes.leadListCreate} component={LeadListCreateView} />
              <Route path={routes.leadList.pathTemplate} component={LeadListDetails} />
              <Route path="*">
                <BasicView>
                  <PageNotFound />
                </BasicView>
              </Route>
            </Switch>
          </StateMachineProvider>
        </NotificationProvider>
      </AccessControlProvider>
    </AppShell>
  </Okta>
);

export default App;
