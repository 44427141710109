// import { useFlags } from 'launchdarkly-react-client-sdk';
import { ReactNode, useContext } from 'react';
import { UserAccess_userAccess_organizations } from 'types/apollo/UserAccess';
import { Permission } from 'components/accessControl';
import { AccessControl } from 'contexts/accessControl';

interface AccessControlData {
  hasFeatureFlag: (featureFlag: string) => boolean;
  hasPermission: (permission: Permission) => boolean;
  loading: boolean;
  protectFeature: (content: ReactNode, featureFlags?: string[]) => ReactNode;
  organizations: UserAccess_userAccess_organizations[];
  id?: number;
}

export const useAccessControl = (): AccessControlData => {
  const AccessControlContext = useContext(AccessControl);
  const flags = []; // useFlags();

  const hasFeatureFlag = (featureFlag: string) => flags[featureFlag];
  const hasPermission = (permission: Permission) =>
    AccessControlContext.permissions.includes(permission);

  const protectFeature = (content: ReactNode, featureFlags: string[] = []) => {
    if (AccessControlContext.loading) {
      return null;
    }

    if (!Array.isArray(featureFlags)) {
      throw Error(`Expected featureFlags to be of type array. Received ${typeof featureFlags}`);
    }

    if (featureFlags.length > 0) {
      const missingFeatureFlags = featureFlags.filter(hasFeatureFlag);

      if (missingFeatureFlags.length === 0) {
        return null;
      }
    }

    return content;
  };

  return {
    hasFeatureFlag,
    hasPermission,
    loading: AccessControlContext.loading,
    protectFeature,
    organizations: AccessControlContext?.organizations || [],
    id: AccessControlContext.id,
  };
};
