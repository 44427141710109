import { gql } from '@apollo/client';

export const GET_COMPANY_CAMPAIGNS = gql`
  query GetCompanyCampaigns($companyId: Int!, $pagination: Pagination) {
    getCampaigns(
      filter: { companyId: $companyId, deleted: { equals: null }, isActive: { equals: "1" } }
      pagination: $pagination
    ) {
      pageInfo {
        hasNext
        hasPrev
        nextNum
        page
        pages
        perPage
        prevNum
        total
      }
      items {
        id
        campaignName
        companyFk
        created
        modified
        isActive
        isRealtime
      }
    }
  }
`;
