import useTheme from '@material-ui/core/styles/useTheme';
import { FC } from 'react';
import { Permission } from './permissions';
import { RequiresPermission } from './RequiresPermission';
import { RequiresPermissionTooltip } from './RequiresPermissionTooltip';

export enum VISIBILITY_VARIANT {
  HIDDEN = 'hidden',
  TOOLTIP = 'tooltip',
}

/**
 * Button whose onClick is protected by permissions. The "tooltip" variant uses a tooltip to indicate to the
 * user why the button is disabled. In some cases it is necessary to hide the button when permissions are not
 * present. For these cases, use the "hidden" variant.
 *
 * @param children
 * @param permissions
 * @param tooltipPlacement
 * @param variant
 * @returns {*}
 * @constructor
 */
export const RequiresPermissionButton: FC<RequiresPermissionButtonProps> = ({
  children,
  permissions = [],
  tooltipPlacement = 'bottom',
  variant = VISIBILITY_VARIANT.TOOLTIP,
}) => {
  const theme = useTheme();

  return (
    <RequiresPermission permissions={permissions}>
      {({ disabled, loading, missingPermissions }) => {
        if (!disabled) {
          return children({ disabled: loading });
        }

        switch (variant) {
          case VISIBILITY_VARIANT.TOOLTIP:
            return (
              <RequiresPermissionTooltip
                children={children}
                enterDelay={theme.transitions.duration.short}
                missingPermissions={missingPermissions}
                placement={tooltipPlacement}
              />
            );
          default:
            return null;
        }
      }}
    </RequiresPermission>
  );
};

interface RequiresPermissionButtonProps {
  children: (props: { disabled?: boolean; loading?: boolean }) => JSX.Element;
  disabled?: boolean;
  permissions?: Permission[];
  tooltipPlacement?:
    | 'bottom'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  variant: VISIBILITY_VARIANT;
}
