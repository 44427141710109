import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { FC } from 'react';

interface LabelProps {
  color: 'success' | 'warning' | 'error' | 'info';
  style?: { [key: string]: string | number };
}

const useStyles = makeStyles((theme) => ({
  container: (props: LabelProps) => ({
    backgroundColor: theme.utils.opacify(theme.palette[`${props.color}`]['200'], 0.2),
    color: theme.palette[`${props.color}`][700],
    borderRadius: '14px',
    padding: '6px 14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  label: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export const Label: FC<LabelProps> = ({ color, children, ...props }) => {
  const classes = useStyles({ color });
  return (
    <div className={classes.container} {...props}>
      <Typography className={classes.label} variant="subtitle2" component="span">
        {children}
      </Typography>
    </div>
  );
};
