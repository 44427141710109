import { defineMessages } from 'react-intl';

export default defineMessages({
  leadListDetailsCardTitleSent: {
    id: 'LeadListDetailsCard.title.sent',
    defaultMessage: "leads were sent to the campaign. They're in Dot's hands now.",
  },
  leadListDetailsCardTitleCleansed: {
    id: 'LeadListDetailsCard.title.cleansed',
    defaultMessage: 'leads are ready to be sent to the campaign.',
  },
  leadListDetailsCardTitleDeleted: {
    id: 'LeadListDetailsCard.title.deleted',
    defaultMessage: 'This lead list was cancelled and removed from the campaign.',
  },
  leadListDetailsCardTitleError: {
    id: 'LeadListDetailsCard.title.error',
    defaultMessage: 'There seemed to be a problem processing this list.',
  },
  leadListDetailsCardTitleUnprocessed: {
    id: 'LeadListDetailsCard.title.unprocessed',
    defaultMessage: 'leads are waiting to be processed.',
  },
  leadListDetailsCardTitleProcessing: {
    id: 'LeadListDetailsCard.title.processing',
    defaultMessage: 'leads are being processed.',
  },
  leadListDetailsCardTitleUnknown: {
    id: 'LeadListDetailsCard.title.unknown',
    defaultMessage: 'This list appears to be in a bad state.',
  },
  leadListDetailsCardSubTitle: {
    id: 'LeadListDetailsCard.subTitle',
    defaultMessage: 'Godspeed, Dot. Godspeed.',
  },
  leadListDetailsCardSubTitleDeletedCount: {
    id: 'LeadListDetailsCard.subTitle',
    defaultMessage: 'leads were processed and cleaned',
  },
  leadListAdditionalDetailsCardTitle: {
    id: 'LeadListAdditionalDetailsCard.title',
    defaultMessage: 'leads were ignored.',
  },
  leadListAdditionalDetailsCardSubTitle: {
    id: 'LeadListAdditionalDetailsCard.subTitle',
    defaultMessage: 'The cleansing system caught some questionable data.',
  },
  companyTitle: {
    id: 'LeadListDetailsCard.companyTitle',
    defaultMessage: 'Company',
  },
  campaignTitle: {
    id: 'LeadListDetailsCard.campaignTitle',
    defaultMessage: 'Campaign',
  },
  fileName: {
    id: 'LeadListDetailsCard.fileName',
    defaultMessage: 'File Name',
  },
  dateTitle: {
    id: 'LeadListDetailsCard.dateTitle',
    defaultMessage: 'Date Imported',
  },
  importAuthorTitle: {
    id: 'LeadListDetailsCard.importAuthorTitle',
    defaultMessage: 'Imported By',
  },
  sendToCampaign: {
    id: 'LeadListDetailsCard.sendToCampaign',
    defaultMessage: 'Send to Campaign',
  },
  estimatedLeads: {
    id: 'LeadListDetailsCard.estimatedLeads',
    defaultMessage: 'Estimated Leads',
  },
  uploadedBy: {
    id: 'LeadListDetailsCard.uploadedBy',
    defaultMessage: 'You',
  },
  deliverability: {
    id: 'LeadListDetailsCard.deliverability',
    defaultMessage: 'Deliverability',
  },
  of: {
    id: 'LeadListDetailsCard.of',
    defaultMessage: 'of',
  },
  leads: {
    id: 'LeadListDetailsCard.leads',
    defaultMessage: 'leads',
  },
  rejected: {
    id: 'LeadListDetailsCard.rejected',
    defaultMessage: 'Rejected',
  },
  downloadIgnoredLeads: {
    id: 'LeadListAdditionalDetailsCard.downloadIgnoredLeads',
    defaultMessage: 'Download Ignored Leads',
  },
});
