import { makeStyles } from '@material-ui/core/styles';
import { useStateMachine, GlobalState } from 'little-state-machine';
import React, { FC, useEffect, useState } from 'react';
import { PageHeading } from 'components/PageHeading';
import { Paper } from 'components/Paper';
import { useIntl } from 'react-intl';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { Nav } from '../Nav';
import { StepContainer } from '../StepContainer';
import messages from './messages';
import Typography from '@material-ui/core/Typography';
import { ListColumnMapper } from '../ListColumnMapper';
import { LeadListTypeIcon } from 'components/LeadListTypeIcon';
import { saveCachedMappings } from '../ListColumnMapper/leadListMappings';

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    width: '100%',
    padding: theme.spacing(3),
    position: 'relative',
  },
  fileName: {
    marginBottom: theme.spacing(4),
  },
  LeadListTypeIcon: {
    position: 'absolute',
    right: '24px',
    top: '24px',
  },
}));

function updateMappings(state: GlobalState, mappings: { [key: string]: string }) {
  return {
    ...state,
    leadListCreate: {
      ...state.leadListCreate,
      mappings: {
        ...state.leadListCreate.mappings,
        ...mappings,
      },
    },
  };
}

function setHasSeenLeadType(state: GlobalState, hasSeen: boolean) {
  return {
    ...state,
    leadListCreate: {
      ...state.leadListCreate,
      hasSeenLeadType: hasSeen,
    },
  };
}

export const StepMapColumns: FC<RouteComponentProps<{}, any, { file?: File }>> = ({ history }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { actions, state } = useStateMachine({ updateMappings, setHasSeenLeadType });
  const [goGreen, setGoGreen] = useState(false);

  useEffect(() => {
    if (
      !!state.leadListCreate.file &&
      !!state.leadListCreate.fileHeaders &&
      !!state.leadListCreate.mappings
    ) {
      setTimeout(() => {
        actions.setHasSeenLeadType(true);
      }, 1000);
    }
  }, []);

  const nextStep = () => {
    if (
      state.leadListCreate.company?.id &&
      state.leadListCreate.mappings &&
      state.leadListCreate.listType
    ) {
      saveCachedMappings(
        state.leadListCreate.company.id,
        state.leadListCreate.listType,
        state.leadListCreate.mappings,
      );
    }

    window.scrollTo({ top: 0, behavior: 'smooth' });
    history.push(`/lead-list-import/review`);

    // setGoGreen(true);
    // setTimeout(() => {
    //   window.scrollTo({ top: 0, behavior: 'smooth' });
    //   history.push(`/lead-list-import/review`);
    // }, 1000);
  };

  if (
    !state.leadListCreate.file ||
    !state.leadListCreate.fileHeaders ||
    !state.leadListCreate.mappings ||
    !state.leadListCreate.listType
  ) {
    return <Redirect to={`/lead-list-import`} />;
  }

  let mappingHasPhone = false;
  let mappingHasName = false;
  let mappingHasDuplicate = false;
  let mappingHasCreated = false;
  const checked: string[] = [];
  Object.values(state.leadListCreate.mappings).forEach((v) => {
    if (v === 'phoneNumber') {
      mappingHasPhone = true;
    }
    if (v === 'firstName' || v === 'fullName') {
      mappingHasName = true;
    }
    if (v === 'created') {
      mappingHasCreated = true;
    }
    if (v !== 'unmapped') {
      if (checked.includes(v)) {
        mappingHasDuplicate = true;
      }
      checked.push(v);
    }
  });

  return (
    <StepContainer>
      <PageHeading title={formatMessage(messages.mappingTitle)} />

      <Typography variant="body1" className={classes.fileName}>
        {state.leadListCreate.file.name}
      </Typography>

      <Paper
        variant="outlined"
        shade={goGreen ? 'success' : undefined}
        className={classes.paperContainer}
      >
        <LeadListTypeIcon
          listType={state.leadListCreate.listType}
          className={classes.LeadListTypeIcon}
        />
        <ListColumnMapper
          headers={state.leadListCreate.fileHeaders}
          onSetMappings={actions.updateMappings}
          mappings={state.leadListCreate.mappings}
          hasPhone={mappingHasPhone}
          hasName={mappingHasName}
          hasDuplicate={mappingHasDuplicate}
          hasCreated={mappingHasCreated}
          listType={state.leadListCreate.listType}
        />
      </Paper>
      <Nav
        onBackClick={history.goBack}
        onForwardClick={nextStep}
        disableForward={goGreen || mappingHasDuplicate || !mappingHasPhone}
        disableBack={goGreen}
      />
    </StepContainer>
  );
};
