import React, { FC } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { BaseModal } from '../BaseModal';

interface ConfirmModalProps {
  onClose: () => void;
  open: boolean;
}

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    position: 'absolute',
    width: '90%',
    maxWidth: '530px',
    maxHeight: '80%',
    padding: theme.spacing(2, 3, 3),
    top: `${40}%`,
    left: `${50}%`,
    transform: `translate(-${50}%, -${50}%)`,
    borderRadius: '16px',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  body: {
    lineHeight: '2rem',
    marginBottom: theme.spacing(6),
  },
  buttons: {
    '& button:not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
  },
}));

export const ConfirmModal: FC<ConfirmModalProps> = ({ open, children, onClose }) => {
  const classes = useStyles();
  return (
    <BaseModal open={open} onClose={onClose}>
      <Paper variant="outlined" className={classes.modalContainer}>
        {children}
      </Paper>
    </BaseModal>
  );
};
