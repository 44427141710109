import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { NoRecordsImage } from 'images';
import messages from './messages';
import { PageEmptyState } from '../components/PageEmptyState';

export const PageNoRecords: FC<{}> = (props) => (
  <PageEmptyState
    image={NoRecordsImage}
    primary={<FormattedMessage {...messages.noRecordsPrimary} />}
    secondary={<FormattedMessage {...messages.noRecordsSecondary} />}
    {...props}
  />
);

export default PageNoRecords;
