import { SortDirection } from 'types/apollo/globalTypes';
import { Column } from './dataTable.types';

type AriaSortDirection = 'none' | 'ascending' | 'descending' | 'other';

export const getAriaSortDirection = (sortDirection: SortDirection): AriaSortDirection => {
  if (!sortDirection) {
    return 'none';
  }

  return sortDirection === SortDirection.ASC ? 'ascending' : 'descending';
};

export const reverseSortDirection = (sortDirection: SortDirection): SortDirection => {
  if (!sortDirection) {
    return SortDirection.ASC;
  }

  return sortDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
};

export function defaultDataGetter<T>({ column, row }: { column: Column<T>; row: T }): string {
  return row[column.dataKey];
}
