import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { FC } from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

export const LinkCellRenderer: FC<LinkCellRendererProps> = ({ label, to }) => {
  const classes = useStyles();
  return (
    <Link to={to} className={classes.link}>
      <Typography variant="inherit">{label}</Typography>
    </Link>
  );
};

export interface LinkCellRendererProps {
  label: string;
  to: string;
}
