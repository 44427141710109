import { defineMessages } from 'react-intl';

export default defineMessages({
  ariaLabel: {
    id: 'LeadListTable.ariaLabel',
    defaultMessage: 'Lead list table',
  },
  headerCleanLeads: {
    id: 'LeadListTable.columnHeader.cleanLeads',
    defaultMessage: 'Clean',
  },
  headerImportedDate: {
    id: 'LeadListTable.columnHeader.importedDate',
    defaultMessage: 'Date Imported',
  },
  headerFlaggedLeads: {
    id: 'LeadListTable.columnHeader.flaggedLeads',
    defaultMessage: 'Flagged',
  },
  headerFileName: {
    id: 'LeadListTable.columnHeader.fileName',
    defaultMessage: 'File Name',
  },
  headerRejectedLeads: {
    id: 'LeadListTable.columnHeader.rejectedLeads',
    defaultMessage: 'Rejected',
  },
  headerCompany: {
    id: 'LeadListTable.columnHeader.headerCompany',
    defaultMessage: 'Company',
  },
  headerCampaign: {
    id: 'LeadListTable.columnHeader.headerCampaign',
    defaultMessage: 'Campaign',
  },
  headerStatus: {
    id: 'LeadListTable.columnHeader.status',
    defaultMessage: 'Status',
  },
  headerTotalLeads: {
    id: 'LeadListTable.columnHeader.totalLeads',
    defaultMessage: 'Leads',
  },
});
