import { ApolloProvider, ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { FC } from 'react';
import { oktaAuth } from 'contexts/okta';
import { setContext } from '@apollo/client/link/context';

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_URL,
});

const authLink = setContext(async (_, { headers }) => {
  oktaAuth.tokenManager.get('accessToken');
  const accessToken = await oktaAuth.tokenManager.get('accessToken');

  return {
    headers: {
      ...headers,
      Accept: 'application/json',
      authorization: accessToken ? `Bearer ${accessToken.value}` : '',
    },
  };
});

const link = ApolloLink.from([authLink, httpLink]);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
  connectToDevTools: process.env.NODE_ENV !== 'production',
});

export const GraphqlProvider: FC<{}> = ({ children }) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
);
