import { Children, FC, ComponentType, ReactNode } from 'react';
import {
  EmptyState,
  EmptyStateImage,
  EmptyStatePrimaryTypography,
  EmptyStateSecondaryTypography,
} from '../index';

export const PageEmptyState: FC<PageEmptyStateProps> = ({
  children,
  image,
  primary,
  secondary,
}) => {
  return (
    <EmptyState>
      <EmptyStateImage image={image} />
      <EmptyStatePrimaryTypography>{primary}</EmptyStatePrimaryTypography>
      <EmptyStateSecondaryTypography>{secondary}</EmptyStateSecondaryTypography>
      {Children.count(children) >= 1 ? children : null}
    </EmptyState>
  );
};

interface PageEmptyStateProps {
  /** Image component to render */
  image: ComponentType<any>;

  /** Message explaining the reason for the empty state */
  primary: ReactNode;

  /** Message explaining a possible solution for the empty state */
  secondary: ReactNode;
}
