import { gql } from '@apollo/client';

export default gql`
  query GetLeadListsForDashboard {
    getLeadLists(
      pagination: { limit: 100 }
      filter: { sentToCampaign: null, deleted: null, listType: PROSPECTIVE }
    ) {
      items {
        id
        status
        deleted
        name
        originalFilename
        sentToCampaign
        created
        totalRows
        estimatedTotalRows
        flagged
        rejected
        uploadedByFk
        uploadedBy {
          id
          firstName
          lastName
          email
        }
        companyFk
        company {
          id
          companyName
        }
        campaignFk
        campaign {
          id
          campaignName
        }
      }
    }
  }
`;
