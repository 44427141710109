import { OktaAuth } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { FC } from 'react';

export const oktaAuth = new OktaAuth({
  issuer: `${process.env.REACT_APP_OKTA_URL_BASE}/oauth2/${process.env.REACT_APP_OKTA_AUDIENCE_ID}`,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: window.location.origin + '/login/callback',
});

export const Okta: FC<{}> = ({ children }) => {
  return <Security oktaAuth={oktaAuth}>{children}</Security>;
};
