import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import Typography from '@material-ui/core/Typography';
import { useDropzone, DropzoneProps } from 'react-dropzone';
import messages from './messages';
import { useIntl } from 'react-intl';
import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

interface FileDropzoneProps extends Pick<DropzoneProps, 'onDrop'> {
  allowedFileTypes?: string;
  selectedFile?: File | null;
  allowContinue?: boolean;
  onClickContinue?: () => void;
}

const useStyles = makeStyles((theme) => ({
  dropBox: {
    cursor: 'pointer',
    backgroundColor: theme.palette.background.paper,
    // border: `1px dashed ${theme.palette.grey['400']}`,
    borderRadius: '8px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '225px',
  },
  dropBoxActive: {
    backgroundColor: theme.palette.grey['400'],
  },
  cloudIcon: {
    fontSize: 54,
    marginBottom: theme.spacing(1),
    color: theme.palette.grey[900],
    opacity: 0.5,
  },
  opacifyText: {
    opacity: 0.5,
  },
  browseText: {
    textDecoration: 'underline',
    marginTop: theme.spacing(1),
  },
  fileIcon: {
    fontSize: 36,
    color: theme.palette.grey[900],
  },
  fileName: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

export const FileDropzone: FC<FileDropzoneProps> = ({ onDrop, allowedFileTypes, selectedFile }) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  if (!!selectedFile) {
    return (
      <div
        {...getRootProps()}
        className={clsx(classes.dropBox, { [classes.dropBoxActive]: isDragActive })}
      >
        <input {...getInputProps()} accept={allowedFileTypes} />
        <InsertDriveFileIcon color="action" className={classes.fileIcon} />
        <Typography variant="subtitle1" className={classes.fileName}>
          {selectedFile.name}
        </Typography>
        <Typography variant="subtitle2" className={classes.opacifyText}>
          {formatFileSize(selectedFile.size)}
        </Typography>
      </div>
    );
  }
  return (
    <div
      {...getRootProps()}
      className={clsx(classes.dropBox, { [classes.dropBoxActive]: isDragActive })}
    >
      <input {...getInputProps()} accept={allowedFileTypes} />
      <CloudUploadIcon color="action" className={classes.cloudIcon} />
      <Typography variant="subtitle2" className={classes.opacifyText}>
        {formatMessage(messages.instrucitonDragAndDrop)}
      </Typography>
      <Typography variant="subtitle2" className={classes.opacifyText}>
        {formatMessage(messages.instructionOr)}
      </Typography>
      <Typography variant="subtitle1" className={classes.browseText}>
        {formatMessage(messages.instructionBrowse)}
      </Typography>
    </div>
  );
};

const formatFileSize = (fileSize: number): string => {
  if (fileSize < 1000) return '<1 KB';
  if (fileSize < 1000000) return `${(fileSize / 1000).toFixed(0)} KB`;
  if (fileSize < 1000000000) return `${(fileSize / 1000000).toFixed(0)} MB`;
  return `${fileSize / 1000000000} GB`;
};
