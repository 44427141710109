import BusinessIcon from '@material-ui/icons/Business';
import Check from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import Flag from '@material-ui/icons/Flag';
import GavelIcon from '@material-ui/icons/Gavel';
import GroupAdd from '@material-ui/icons/GroupAdd';
import HomeIcon from '@material-ui/icons/Home';
import MenuIcon from '@material-ui/icons/Menu';
import Pencil from '@material-ui/icons/Edit';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import PhoneDisabledIcon from '@material-ui/icons/PhoneDisabled';
import PhoneIcon from '@material-ui/icons/Phone';
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt';
import SettingsIcon from '@material-ui/icons/Settings';
import SettingsPhoneIcon from '@material-ui/icons/SettingsPhone';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';

export const AccountIcon = SettingsIcon;
export const AddLeadsIcon = GroupAdd;
export const CampaignIcon = TrendingUpIcon;
export const CheckIcon = Check;
export const DashboardIcon = HomeIcon;
export const DncComplainerIcon = GavelIcon;
export const DncIcon = PhoneDisabledIcon;
export const DncLitigatorIcon = GavelIcon;
export const ExitToAppIconIcon = ExitToAppIcon;
export const ExpandCloseIcon = ExpandLessIcon;
export const ExpandOpenIcon = ExpandMoreIcon;
export const FlagIcon = Flag;
export const OrganizationIcon = BusinessIcon;
export const PencilIcon = Pencil;
export const PhoneLookupIcon = SettingsPhoneIcon;
export const PhoneNumberIcon = PhoneIcon;
export const RightArrowIcon = ArrowRightAlt;
export const UserIcon = PersonOutlineIcon;

export { CloseIcon, DeleteIcon, FilterListIcon, MenuIcon };
