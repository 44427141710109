import { ListType } from 'types/apollo/globalTypes';

/**
 * @property value {string} Key that should be what csv columns are mapped too
 * @property regex {RegEx} Used in predicting the auto mapping of uploaded csv
 *
 * To add new (hard coded) mappings, follow the format. To add a common alternative
 * name of a column, add that to the respective regex field. ex: locality could
 * commonly have city as its mapping, so you'll notice city is an option in the regex.
 *
 * NOTE: These should match the Mappings type in lead-platform
 */
export const leadListMappings = [
  { name: 'Created', value: 'created', regex: /created/gi },
  { name: 'Email', value: 'email', regex: /mail/gi },
  { name: 'Ext Created', value: 'extCreated', regex: /ext/gi },
  { name: 'Ext Lead Id', value: 'extLeadId', regex: /lead|id/gi },
  { name: 'First Name', value: 'firstName', regex: /first|given|(^fn)/gi },
  { name: 'Full Name', value: 'fullName', regex: /full/gi },
  { name: 'IP Address', value: 'ipAddress', regex: /(^ip)/gi },
  { name: 'Last Name', value: 'lastName', regex: /last|^sur|(^ln)/gi },
  { name: 'Locality', value: 'locality', regex: /loca|city/gi },
  { name: 'Phone Number', value: 'phoneNumber', regex: /phone|number|tel|cell|contact/gi },
  { name: 'Postal Code', value: 'postalCode', regex: /post|zip|area/gi },
  { name: 'Region', value: 'region', regex: /region|state|(^st)/gi },
  { name: 'Source', value: 'source', regex: /source|campaign|src/gi },
  { name: 'Timezone', value: 'timezone', regex: /time|zone|tz/gi },
];

export const predictLeadListMappings = (
  headers: string[],
  companyId?: string | number,
  listType?: ListType,
): { [key: string]: string } => {
  if (!headers || headers.length === 0) {
    return {};
  }

  const cache = companyId && listType ? getCachedMappings(companyId, listType) : {};

  // first, do the default mapping
  const mapping = leadListMappings.reduce((acc, cur) => {
    const x = headers.find((header) => header.match(cur.regex));
    if (x) {
      return { ...acc, [x]: cur.value };
    }
    return acc;
  }, {});

  const mappingAsArray: string[] = Object.values(mapping);

  // If we dont find any name column, we'll do a last check for a full name column
  if (
    !mappingAsArray.includes('firstName') &&
    !mappingAsArray.includes('lastName') &&
    !mappingAsArray.includes('fullName')
  ) {
    const h = headers.find((header) => header.match(/name/gi));
    if (h) {
      mapping[h] = 'fullName';
    }
  }

  headers.forEach((column) => {
    if (cache[column]) {
      mapping[column] = cache[column];
    }
  });

  return mapping;
};

export const LS_CACHE_NAME = 'list-column-mapping-cache';

export const getCachedMappings = (
  companyId: string | number,
  listType: ListType,
): { [key: string]: string } => {
  const cache = localStorage.getItem(LS_CACHE_NAME);
  if (cache) {
    const parsedCache = JSON.parse(cache);
    let companyCache = parsedCache[companyId];
    if (companyCache) {
      if (!companyCache[listType]) {
        const companyKeys = Object.keys(companyCache);
        if (companyKeys.find((key) => !ListType[key])) {
          companyCache = Object.keys(ListType).reduce((newCompanyCache, listTypeKey) => {
            newCompanyCache[listTypeKey] = companyCache;
            return newCompanyCache;
          }, {});
          parsedCache[companyId] = companyCache;
          localStorage.setItem(LS_CACHE_NAME, JSON.stringify(parsedCache));
        }
      }
      return companyCache[listType] || {};
    }
  }
  return {};
};

export const saveCachedMappings = (
  companyId: string | number,
  listType: ListType,
  mappings: { [key: string]: string },
) => {
  const cache = localStorage.getItem(LS_CACHE_NAME);
  let parsedCache = {};
  if (cache) {
    parsedCache = JSON.parse(cache);
  }
  if (!parsedCache[companyId]) {
    parsedCache[companyId] = {};
  }
  // use this to pull the mappings to make sure the backwards compatibility runs correctly
  const cachedMappings = getCachedMappings(companyId, listType);
  parsedCache[companyId][listType] = { ...cachedMappings, ...mappings };
  localStorage.setItem(LS_CACHE_NAME, JSON.stringify(parsedCache));
};
