import Box from '@material-ui/core/Box';
import { ComponentType } from 'react';
import { PageLoading, PageError, PageNoRecords, PageEmptyResults } from '../EmptyState';
import { DataTableContainerProps } from 'components/DataTable';

type StrippedDataTableContainerProps<T> = Omit<DataTableContainerProps<T>, 'columns' | 'ariaLabel'>;
interface TableBodyProps<T> extends StrippedDataTableContainerProps<T> {
  loading?: boolean;
  error?: boolean;
  called?: boolean;
  resultsFiltered?: boolean;
  tableComponent: ComponentType<StrippedDataTableContainerProps<T>>;
}

export function TableBody<T>({
  resultsFiltered,
  tableComponent: TableComponent,
  called,
  loading,
  error,
  ...tableProps
}: TableBodyProps<T>): JSX.Element {
  if (!called || loading) {
    return (
      <Box py={16}>
        <PageLoading />
      </Box>
    );
  }

  if (error) {
    return <PageError />;
  }

  if (!tableProps.items.length) {
    return <Box py={8}>{resultsFiltered ? <PageEmptyResults /> : <PageNoRecords />}</Box>;
  }

  return <TableComponent {...tableProps} />;
}
