import { defineMessages } from 'react-intl';

export default defineMessages({
  assignTitle: {
    id: 'CampaignSelector.assignTitle',
    defaultMessage: 'Select List Type',
  },
  listType: {
    id: 'CampaignSelector.listType',
    defaultMessage: 'List Type',
  },
  company: {
    id: 'CampaignSelector.company',
    defaultMessage: 'Company',
  },
  campaign: {
    id: 'CampaignSelector.campaign',
    defaultMessage: 'Campaign',
  },
  selectCompany: {
    id: 'CampaignSelector.selectCompany',
    defaultMessage: 'Select Company',
  },
  selectCampaign: {
    id: 'CampaignSelector.selectCampaign',
    defaultMessage: 'Select Campaign',
  },
  new: {
    id: 'CampaignSelector.new',
    defaultMessage: 'New',
  },
  closed: {
    id: 'CampaignSelector.closed',
    defaultMessage: 'Closed',
  },
  endContact: {
    id: 'CampaignSelector.endContact',
    defaultMessage: 'End Contact',
  },
  doNotContact: {
    id: 'CampaignSelector.doNotContact',
    defaultMessage: 'Do Not Contact',
  },
});
