import { gql } from '@apollo/client';

export const GET_LEAD_LIST = gql`
  query GetLeadLists($pagination: Pagination) {
    getLeadLists(pagination: $pagination) {
      items {
        id
        companyId: companyFk
        company {
          id
          companyName
        }
        campaignId: campaignFk
        campaign {
          id
          campaignName
        }
        created
        name
        status
        sentToCampaign
        estimatedTotalRows
        deleted
        totalRows
      }
      pageInfo {
        hasNext
        hasPrev
        nextNum
        page
        pages
        perPage
        prevNum
        total
      }
    }
  }
`;
