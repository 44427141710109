import Typography from '@material-ui/core/Typography';
import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  body: {
    lineHeight: '2rem',
    marginBottom: theme.spacing(6),
  },
}));

export const ConfirmModalBody: FC<{}> = ({ children }) => {
  const classes = useStyles();

  return (
    <Typography variant="body1" className={classes.body}>
      {children}
    </Typography>
  );
};
