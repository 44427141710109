import makeStyles from '@material-ui/core/styles/makeStyles';
import { FC } from 'react';
import { ButtonList } from '../../layout';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

export const EmptyStateActions: FC<{}> = ({ children }) => {
  const classes = useStyles();
  return <ButtonList className={classes.root}>{children}</ButtonList>;
};

export default EmptyStateActions;
