import { Column } from 'components/DataTable';
import { GetLeadLists_getLeadLists_items } from 'types/apollo/GetLeadLists';
import routes from 'routes';
import { DateRenderer, DateRendererProps, TextRenderer } from 'components/DataTable/renderers';
import {
  leadCountRenderer,
  processingStatusRenderer,
  processingStatusRendererProps,
} from './cellRenderers';
import messages from './messages';

const created: Column<GetLeadLists_getLeadLists_items, DateRendererProps> = {
  align: 'right',
  cellRenderer: DateRenderer,
  dataGetter: ({ column, row }) => {
    return row[column.dataKey];
  },
  dataKey: 'created',
  enableSort: true,
  label: messages.headerImportedDate,
  style: {
    maxWidth: '150px',
  },
};

const name: Column<GetLeadLists_getLeadLists_items, { label: string }> = {
  cellRenderer: TextRenderer,
  dataGetter: ({ row }) => ({ label: row.name, to: routes.leadList(row) }),
  dataKey: 'name',
  enableSort: true,
  label: messages.headerFileName,
  style: {
    paddingLeft: '32px',
    maxWidth: '300px',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    hyphens: 'auto',
  },
};

const company: Column<GetLeadLists_getLeadLists_items, { label: string }> = {
  cellRenderer: TextRenderer,
  dataGetter: ({ row }) => ({ label: row?.company?.companyName || '' }),
  dataKey: 'company',
  label: messages.headerCompany,
};

const campaign: Column<GetLeadLists_getLeadLists_items, { label: string }> = {
  cellRenderer: TextRenderer,
  dataKey: 'campaign',
  label: messages.headerCampaign,
  dataGetter: ({ row }) => ({ label: row?.campaign?.campaignName || '' }),
  style: {
    paddingLeft: '32px',
    maxWidth: '300px',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    hyphens: 'auto',
  },
};

const totalLeads: Column<GetLeadLists_getLeadLists_items, number | null> = {
  align: 'right',
  cellRenderer: leadCountRenderer,
  dataGetter: ({ row }) => {
    return row.totalRows || row.estimatedTotalRows;
  },
  dataKey: 'totalRows',
  label: messages.headerTotalLeads,
};

const status: Column<GetLeadLists_getLeadLists_items, processingStatusRendererProps> = {
  cellRenderer: processingStatusRenderer,
  dataKey: 'status',
  label: messages.headerStatus,
  dataGetter: ({ row }) => {
    const { status, sentToCampaign, deleted } = row;
    return { status, sentToCampaign, deleted };
  },
};

export const LeadListColumns = [name, totalLeads, company, campaign, created, status];
