import makeStyles from '@material-ui/core/styles/makeStyles';
import { FC, ComponentType } from 'react';

const useStyles = makeStyles((theme) => ({
  image: {
    height: 'auto',
    marginBottom: theme.spacing(8),
    maxWidth: 240, // The image must be responsive
    width: '100%',
  },
}));

export const EmptyStateImage: FC<EmptyStateImageProps> = ({ image: Image }) => {
  const classes = useStyles();

  return <Image className={classes.image} />;
};

interface EmptyStateImageProps {
  image: ComponentType<any>;
}

export default EmptyStateImage;
