import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import { FC } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Permission } from './';

const messages = defineMessages({
  tooltipMessage: {
    id: 'RequiresPermissionTooltip.tooltipMessage',
    defaultMessage:
      'Missing required {permissionCount, plural, one {permission} other {permissions}}: {permissions}',
  },
});

export const RequiresPermissionTooltip: FC<RequiresPermissionTooltipProps> = ({
  children,
  missingPermissions,
  ...props
}) => {
  const { formatMessage } = useIntl();

  // Disabled elements do not trigger mouse events, adding a non-disabled element such as
  // a <span> wrapper solves this problem
  return (
    <Tooltip
      arrow
      title={formatMessage(messages.tooltipMessage, {
        permissionCount: missingPermissions.length,
        permissions: missingPermissions.join(', '),
      })}
      {...props}
    >
      <span>{children({ disabled: true })}</span>
    </Tooltip>
  );
};

interface RequiresPermissionTooltipProps extends Omit<TooltipProps, 'title' | 'children'> {
  children: (props: { disabled: boolean }) => JSX.Element;
  missingPermissions: Permission[];
}
