import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Typography from '@material-ui/core/Typography';
import { useDropzone, DropzoneProps } from 'react-dropzone';
import messages from './messages';
import { useIntl } from 'react-intl';
import React, { FC, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import ButtonBase from '@material-ui/core/ButtonBase';

interface ScreenFileDropzoneProps extends Pick<DropzoneProps, 'onDrop'> {
  selectedFile?: File;
}

const useStyles = makeStyles((theme) => ({
  dropBox: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0.2,
    transition: 'all 0.25s ease-in-out',
  },
  dropBoxActive: {
    backgroundColor: theme.palette.grey['400'],
  },
  browseContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    bgcolor: 'green',
    width: '40%',
  },
  cloudIcon: {
    fontSize: 54,
    color: theme.palette.grey[900],
    opacity: 0.5,
  },
  uploadText: {
    cursor: 'pointer',
    opacity: 0.5,
    fontWeight: theme.typography.fontWeightRegular,
    marginBottom: theme.spacing(1),
    color: theme.palette.grey[900],
  },
  browseText: {
    textDecoration: 'underline',
    cursor: 'pointer',
    opacity: 0.5,
    marginTop: theme.spacing(1),
  },
  fileIcon: {
    fontSize: 36,
  },
  fileName: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

export const ScreenFileDropzone: FC<ScreenFileDropzoneProps> = ({ onDrop, selectedFile }) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const [highlight, setHighlight] = useState(false);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const showHighlight = () => {
    setHighlight(true);
  };

  const hideHighlight = () => {
    setHighlight(false);
  };

  const { onClick, ...controlProps } = getRootProps();

  return (
    <>
      <div
        {...controlProps}
        className={clsx(classes.dropBox, { [classes.dropBoxActive]: isDragActive || highlight })}
      >
        <input {...getInputProps()} accept=".csv" />
      </div>
      <ButtonBase
        className={classes.browseContainer}
        disableRipple
        onClick={onClick}
        {...controlProps}
        onMouseEnter={showHighlight}
        onMouseLeave={hideHighlight}
      >
        <CloudUploadIcon color="action" className={classes.cloudIcon} />
        <Typography variant="subtitle2" className={classes.uploadText}>
          {formatMessage(messages.instrucitonDragAndDrop)}
        </Typography>
        <Typography variant="subtitle2" className={classes.uploadText}>
          {formatMessage(messages.instructionOr)}
        </Typography>
        <Typography variant="subtitle1" className={clsx(classes.browseText)}>
          {formatMessage(messages.instructionBrowse)}
        </Typography>
      </ButtonBase>
    </>
  );
};
