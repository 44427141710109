import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import * as icons from '../../../icons';
import commonMessages from '../../../messages';
import routes from '../../../routes';
import { LogoutButton } from 'components/ActionButtons';
import { NavListItem } from './AppNavDrawer.types';
import messages from './messages';

export interface Section {
  id: string;
  featureFlags?: string[];
  subHeader?: ReactNode;
  items: NavListItem[];
}

const configuration: Section[] = [
  {
    id: 'dashboard',
    featureFlags: [],
    items: [
      {
        id: 'workspaceDashboard',
        icon: icons.DashboardIcon,
        label: <FormattedMessage {...commonMessages.workspaceDashboard} />,
        to: routes.dashboard,
      },
    ],
  },
  {
    id: 'campaign',
    featureFlags: [],
    subHeader: <FormattedMessage {...messages.subheaderCampaignManagement} />,
    items: [
      {
        id: 'workspaceCampaigns',
        icon: icons.CampaignIcon,
        label: <FormattedMessage {...commonMessages.workspaceCampaigns} />,
        to: routes.campaignList,
      },
    ],
  },
  {
    id: 'communication',
    featureFlags: [],
    subHeader: <FormattedMessage {...messages.subheaderCommunication} />,
    items: [
      {
        id: 'workspacePhoneNumbers',
        featureFlags: [],
        icon: icons.PhoneNumberIcon,
        label: <FormattedMessage {...commonMessages.workspacePhoneNumbers} />,
        to: routes.phoneNumbers,
      },
      {
        id: 'workspacePhoneLookups',
        featureFlags: [],
        icon: icons.PhoneLookupIcon,
        label: <FormattedMessage {...commonMessages.workspacePhoneLookups} />,
        to: routes.phoneLookups,
      },
      {
        id: 'workspaceDNC',
        featureFlags: [],
        icon: icons.DncIcon,
        label: <FormattedMessage {...commonMessages.workspaceDNCs} />,
        to: routes.dncs,
      },
      {
        id: 'workspaceComplainers',
        featureFlags: [],
        icon: icons.DncComplainerIcon,
        label: <FormattedMessage {...commonMessages.workspaceDNCComplainers} />,
        to: routes.dncComplainers,
      },
      {
        id: 'workspaceLitigator',
        featureFlags: [],
        icon: icons.DncLitigatorIcon,
        label: <FormattedMessage {...commonMessages.workspaceDNCLitigators} />,
        to: routes.dncLitigators,
      },
    ],
  },
  {
    id: 'configuration',
    subHeader: <FormattedMessage {...messages.subheaderConfiguration} />,
    items: [
      {
        id: 'workspaceOrganization',
        container: true,
        featureFlags: [],
        icon: icons.OrganizationIcon,
        label: <FormattedMessage {...commonMessages.organization} />,
        items: [
          {
            id: 'organizationUsers',
            icon: icons.UserIcon,
            label: <FormattedMessage {...commonMessages.organizationUsers} />,
            to: routes.organizationUsers,
          },
        ],
      },
      {
        id: 'workspaceAccount',
        icon: icons.AccountIcon,
        featureFlags: [],
        label: <FormattedMessage {...commonMessages.workspaceAccount} />,
        to: routes.account,
      },
      {
        id: 'logout',
        component: LogoutButton,
      },
    ],
  },
];

export default configuration;
