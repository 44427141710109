import { defineMessages } from 'react-intl';

export default defineMessages({
  dnc: {
    id: 'leadListTypeIcon.dnc',
    defaultMessage: 'Do Not Contact',
  },
  endContact: {
    id: 'leadListTypeIcon.endContact',
    defaultMessage: 'End Contact',
  },
  close: {
    id: 'leadListTypeIcon.close',
    defaultMessage: 'Closed',
  },
  prospective: {
    id: 'leadListTypeIcon.prospective',
    defaultMessage: 'New',
  },
});
