import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MUISelect, { SelectProps } from '@material-ui/core/Select';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import React from 'react';

export const Select = React.forwardRef<HTMLInputElement, SelectProps>(
  ({ label, id, children, disabled, ...props }, ref) => {
    return (
      <FormControl variant="outlined" fullWidth>
        <InputLabel id={`${id}-label`} disabled={disabled}>
          {label}
        </InputLabel>
        <MUISelect
          labelId={`${id}-label`}
          id={id}
          label={label}
          disabled={disabled}
          innerRef={ref}
          IconComponent={KeyboardArrowDownIcon}
          {...props}
        >
          {children}
        </MUISelect>
      </FormControl>
    );
  },
);
