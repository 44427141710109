import { FC, ReactNode } from 'react';
import { AppHeader, AppBarProps } from './AppHeader';
import { PageContent, PageContentProps } from './PageContent';

export const BasicView: FC<BasicViewProps> = ({ actions, children, maxWidth, hideHeader }) => {
  return (
    <>
      {!hideHeader && <AppHeader>{actions}</AppHeader>}
      <PageContent maxWidth={maxWidth}>{children}</PageContent>
    </>
  );
};

export interface BasicViewProps extends AppBarProps, PageContentProps {
  /** Header actions */
  actions?: ReactNode;
  hideHeader?: boolean;
}
