import Typography from '@material-ui/core/Typography';
import { useIntl } from 'react-intl';
import { FC, useEffect } from 'react';
import { useStateMachine } from 'little-state-machine';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { useNotifications } from 'components/notifications';
import messages from './messages';
import { useMutation } from '@apollo/client';
import {
  GenerateLeadListSignedURL,
  GenerateLeadListSignedURLVariables,
} from 'types/apollo/GenerateLeadListSignedURL';
import { CreateLeadList, CreateLeadListVariables } from 'types/apollo/CreateLeadList';
import { ListType } from 'types/apollo/globalTypes';
import GEN_LEAD_LIST_SIGNED_URL from '../../generateLeadListSignedUrl.graphql';
import CREATE_LEAD_LIST from '../../createLeadList.graphql';

export const StepImporting: FC<RouteComponentProps> = ({ history }) => {
  const { formatMessage } = useIntl();
  const {
    state: { leadListCreate },
  } = useStateMachine();
  const { notifyError, notifySuccess } = useNotifications();

  const [createLeadList] = useMutation<CreateLeadList, CreateLeadListVariables>(CREATE_LEAD_LIST);
  const [generateSignedUrl] = useMutation<
    GenerateLeadListSignedURL,
    GenerateLeadListSignedURLVariables
  >(GEN_LEAD_LIST_SIGNED_URL);

  const goHome = () => {
    history.push('/');
  };

  const importList = async () => {
    if (
      !leadListCreate.listType ||
      (leadListCreate.listType === ListType.PROSPECTIVE && !leadListCreate.campaign?.id) ||
      !leadListCreate.company?.id ||
      !leadListCreate.file?.name ||
      !leadListCreate.mappings
    ) {
      console.info(
        'We are missing information so we cannot upload this list. We are taking you back to the start.',
      );
      return;
    }
    // We need to swap the mappings key/value pair for the server
    const swappedMappings = {};
    for (const [key, value] of Object.entries(leadListCreate.mappings)) {
      swappedMappings[value] = key;
    }

    const newLeadList = await createLeadList({
      variables: {
        input: {
          companyId: leadListCreate.company.id,
          campaignId: !!leadListCreate?.campaign?.id ? leadListCreate.campaign.id : undefined,
          fileName: leadListCreate.file.name,
          estimatedTotalRows: leadListCreate.estimatedTotalRows,
          columnMapping: swappedMappings,
          listType: leadListCreate.listType,
        },
      },
    });

    if (!newLeadList.data?.createLeadList.id) {
      notifyError(formatMessage(messages.newLeadListErrorNotification), 'newLeadList-error');
      goHome();
      return;
    }

    const signedUrl = await generateSignedUrl({
      variables: {
        id: newLeadList.data.createLeadList.id,
        fileName: leadListCreate.file.name,
        listType: leadListCreate.listType,
      },
    });

    if (!signedUrl.data?.generateLeadListSignedURL.signedUrl) {
      notifyError(
        formatMessage(messages.genLeadListSignedURLErrorNotification),
        'genLeadListSignedURL-error',
      );
      goHome();
      return;
    }

    await fetch(signedUrl.data.generateLeadListSignedURL.signedUrl, {
      method: 'PUT',
      body: leadListCreate.file,
      headers: {
        'content-type': 'application/octet-stream',
      },
    });

    notifySuccess(formatMessage(messages.newLeadListSuccessNotification), 'newLeadList-success');
    goHome();
  };

  useEffect(() => {
    importList();
  }, []);

  if (
    (leadListCreate.listType === ListType.PROSPECTIVE && !leadListCreate.campaign?.id) ||
    !leadListCreate.file?.name ||
    !leadListCreate.mappings
  ) {
    return <Redirect to={`/lead-list-import`} />;
  }

  return <Typography variant="h1">{formatMessage(messages.hangTight)}</Typography>;
};
