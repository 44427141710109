import { FC } from 'react';
import MUILinearProgress, {
  LinearProgressProps as MUILinearProgressProps,
} from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';

interface LinearProgressProps extends Omit<MUILinearProgressProps, 'color'> {
  color?: 'primary' | 'secondary' | 'orange' | 'yellow' | 'green' | 'red';
}

const useStyles = makeStyles((theme) => ({
  track: {
    backgroundColor: theme.palette.grey[200],
  },
  orangeBar: {
    backgroundColor: '#FABEAB',
  },
  redBar: {
    backgroundColor: theme.palette.error.main,
  },
  yellowBar: {
    backgroundColor: '#FFDD76',
  },
  greenBar: {
    backgroundColor: '#00C389',
  },
  primaryBar: {
    backgroundColor: theme.palette.primary.main,
  },
  secondaryBar: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export const LinearProgress: FC<LinearProgressProps> = ({ color = 'primary', ...props }) => {
  const classes = useStyles();

  return (
    <MUILinearProgress
      classes={{
        barColorPrimary: classes[`${color}Bar`],
        root: classes.track,
      }}
      color="primary"
      {...props}
    />
  );
};
