import { defineMessages } from 'react-intl';

export default defineMessages({
  missingPermissionsPrimary: {
    id: 'MissingPermissions.primaryMessage',
    defaultMessage: `This page requires permissions that you do not have.`,
  },
  missingPermissionsSecondary: {
    id: 'MissingPermissions.secondaryMessage',
    defaultMessage:
      'Contact an administrator in your organization to request the following {permissionCount, plural, one {permission} other {permissions}}: <b>{permissions}‍</b>',
  },
});
