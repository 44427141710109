import { makeStyles } from '@material-ui/core/styles';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { CloseIcon } from 'icons';
import { IconButton, IconButtonProps } from 'components/IconButton';
import messages from './messages';

const useStyles = makeStyles((theme) => ({
  closeIcon: {
    color: theme.palette.grey[600],
    fontSize: '24px',
  },
}));

/**
 * IconButton for dismissing notifications
 */
export const DismissIconButton: FC<Omit<IconButtonProps, 'ariaLabel' | 'icon'>> = ({
  tooltipProps,
  ...iconButtonProps
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  return (
    <IconButton
      ariaLabel={formatMessage(messages.dismissAriaLabel)}
      tooltipProps={
        !!tooltipProps
          ? {
              ...tooltipProps,
              title: formatMessage(messages.dismissTooltip),
            }
          : undefined
      }
      {...iconButtonProps}
    >
      <CloseIcon className={classes.closeIcon} />
    </IconButton>
  );
};

export default DismissIconButton;
