import { FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import messages from './messages';
import { useOktaAuth } from '@okta/okta-react';

export const LogoutButton: FC<LogoutButtonProps> = ({ className, onClick }) => {
  const { oktaAuth } = useOktaAuth();

  const handleClick = useCallback(async () => {
    onClick && onClick();
    await oktaAuth.signOut();
  }, [onClick]);

  return (
    <ListItem button className={className} onClick={handleClick}>
      <ListItemText primary={<FormattedMessage {...messages.label} />} />
    </ListItem>
  );
};

interface LogoutButtonProps {
  className?: string;
  onClick?: () => void;
}

export default LogoutButton;
