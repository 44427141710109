import { useQuery } from '@apollo/client';
import Box from '@material-ui/core/Box';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { BasicView } from 'components/layout';
import { PageHeading } from 'components/PageHeading';
import { RouteComponentProps } from 'react-router-dom';
import { GET_LEAD_LIST } from './getLeadList.graphql';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import messages from './messages';
import { useIntl } from 'react-intl';
import format from 'date-fns/format';
import { Paper } from 'components/Paper';
import clsx from 'clsx';
import { FormattedNumber } from 'react-intl';
import { LeadListStatusLabel } from 'components/LeadListStatusLabel';
import Skeleton from '@material-ui/lab/Skeleton';
import { PageError } from 'components/EmptyState';
import { statusMessageFormatter, LEAD_LIST_PROCESSING_STATUS } from 'components/leadListStatus';
import { InfoPoint } from './components/InfoPoint';
import { LeadListTypeIcon } from 'components/LeadListTypeIcon';
import { useAccessControl } from 'hooks/useAccessControl';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    maxWidth: '530px',
    marginBottom: '16px',
  },
  leadListFileName: {
    marginRight: '8px',
    overflow: 'hidden',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    hyphens: 'auto',
  },
  categoryLabel: {
    marginRight: '2px',
  },
}));

export const LeadListDetails: FC<RouteComponentProps> = ({ history }) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const { id } = useAccessControl();

  const { id: leadListId } = useParams<{ campaignId: string; id: string }>();

  const { loading, error, data } = useQuery(GET_LEAD_LIST, {
    variables: { leadListId: parseInt(leadListId, 10) },
  });

  const leadList = data?.getLeadList;

  if (loading) {
    return (
      <BasicView>
        <Box display="flex" flexDirection="column" alignItems="center" mb={6}>
          <PageHeading onBackClick={history.goBack} />
          <Skeleton width="528px" height="419px" variant="rect" style={{ borderRadius: '8px' }} />
        </Box>
      </BasicView>
    );
  }

  if (error) {
    return <PageError />;
  }

  let mainLeadCount;
  let ofCount = 0;
  if (
    leadList.status === LEAD_LIST_PROCESSING_STATUS.UNPROCESSED ||
    leadList.status === LEAD_LIST_PROCESSING_STATUS.PROCESSING
  ) {
    mainLeadCount = leadList.estimatedTotalRows;
  } else if (leadList.status === LEAD_LIST_PROCESSING_STATUS.PROCESSED && !leadList.deleted) {
    mainLeadCount = leadList.totalRows - leadList.rejected;
    ofCount = leadList.totalRows;
  }

  return (
    <BasicView>
      <Box display="flex" flexDirection="column" alignItems="center">
        <PageHeading onBackClick={history.goBack} />

        <Paper className={clsx(classes.container)}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="left"
            justifyContent="space-between"
            padding={3}
          >
            <Box
              display="flex"
              flexDirection="row"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Typography variant="h2" style={{ marginBottom: '16px', marginRight: '16px' }}>
                <FormattedNumber value={mainLeadCount} />
                {!!mainLeadCount && ' '}
                <span style={{ fontWeight: 400 }}>
                  {!!ofCount && `${formatMessage(messages.of)} `}
                  {!!ofCount && <FormattedNumber value={ofCount} />}
                  {!!ofCount && ' '}
                  {`${formatMessage(
                    statusMessageFormatter(leadList, {
                      deleted: messages.leadListDetailsCardTitleDeleted,
                      errors: messages.leadListDetailsCardTitleError,
                      processed: messages.leadListDetailsCardTitleCleansed,
                      processing: messages.leadListDetailsCardTitleProcessing,
                      sent: messages.leadListDetailsCardTitleSent,
                      unprocessed: messages.leadListDetailsCardTitleUnprocessed,
                      unknown: messages.leadListDetailsCardTitleUnknown,
                    }),
                  )}`}
                </span>
              </Typography>
              <LeadListTypeIcon listType={leadList.listType} />
            </Box>
            {!!leadList.sentToCampaign && (
              <Typography variant="body1" style={{ marginBottom: '24px' }}>
                {formatMessage(messages.leadListDetailsCardSubTitle)}
              </Typography>
            )}
            {!!leadList.deleted && leadList.status === LEAD_LIST_PROCESSING_STATUS.PROCESSED && (
              <Typography variant="body1" style={{ marginBottom: '24px' }}>
                {`${leadList.totalRows - leadList.rejected} `}
                {`${formatMessage(messages.of)} ${leadList.totalRows} `}
                {formatMessage(messages.leadListDetailsCardSubTitleDeletedCount)}
              </Typography>
            )}
            <Divider />
            <Box py={3} pl={4}>
              <Grid container spacing={2}>
                <InfoPoint
                  category={formatMessage(messages.fileName)}
                  info={
                    <Box display="flex" alignItems="center">
                      <Typography className={classes.leadListFileName} variant="body1">
                        {leadList.name}
                      </Typography>
                      <LeadListStatusLabel
                        status={leadList.status}
                        sentToCampaign={leadList.sentToCampaign}
                        deleted={leadList.deleted}
                      />
                    </Box>
                  }
                />

                <InfoPoint
                  category={formatMessage(messages.companyTitle)}
                  info={leadList.company?.companyName}
                />

                <InfoPoint
                  category={formatMessage(messages.campaignTitle)}
                  info={leadList.campaign?.campaignName}
                />

                <InfoPoint
                  category={formatMessage(messages.dateTitle)}
                  info={format(new Date(leadList.created), 'MMM d, yyyy h:mm aaa')}
                />

                <InfoPoint
                  category={formatMessage(messages.importAuthorTitle)}
                  info={`${
                    leadList.uploadedByFk === id ? `${formatMessage(messages.uploadedBy)} (` : ''
                  }${leadList.uploadedBy?.firstName} ${leadList.uploadedBy?.lastName}${
                    leadList.uploadedByFk === id ? ')' : ''
                  }`}
                />
              </Grid>
            </Box>
            <Divider />
            <Box style={{ minHeight: '40px' }}></Box>
          </Box>
        </Paper>

        {leadList.rejected !== null && (
          <Paper className={clsx(classes.container)}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="left"
              justifyContent="space-between"
              padding={3}
            >
              <Box mb={2}>
                {leadList.rejected > 0 ? (
                  <Typography variant="h2">
                    <FormattedNumber value={leadList.rejected} />{' '}
                    <span style={{ fontWeight: 400 }}>
                      {`${formatMessage(messages.of)} `}
                      <FormattedNumber value={leadList.totalRows} />
                      {` ${formatMessage(messages.leadListAdditionalDetailsCardTitle)}`}
                    </span>
                  </Typography>
                ) : (
                  <Typography variant="h2">
                    {'0 '}
                    <span style={{ fontWeight: 400 }}>
                      {formatMessage(messages.leadListAdditionalDetailsCardTitle)}
                    </span>
                  </Typography>
                )}
              </Box>

              {!!leadList.rejected && (
                <Typography variant="body1" style={{ marginBottom: '16px' }}>
                  {formatMessage(messages.leadListAdditionalDetailsCardSubTitle)}
                </Typography>
              )}
              <Divider />
              <Box py={3} pl={4}>
                <Grid container spacing={2}>
                  <InfoPoint
                    category={formatMessage(messages.rejected)}
                    info={<FormattedNumber value={leadList.rejected} />}
                  />

                  <InfoPoint
                    category={formatMessage(messages.deliverability)}
                    info={` ${(
                      (100 * (leadList.totalRows - leadList.rejected)) /
                      leadList.totalRows
                    ).toFixed(2)}%`}
                  />
                </Grid>
              </Box>
              <Divider />
              <Box style={{ minHeight: '40px' }}></Box>
            </Box>
          </Paper>
        )}
      </Box>
    </BasicView>
  );
};
