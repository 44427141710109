/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ListType {
  CLOSE = 'CLOSE',
  DNC = 'DNC',
  ENDCONTACT = 'ENDCONTACT',
  PROSPECTIVE = 'PROSPECTIVE',
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface CreateLeadListInput {
  companyId?: number | null;
  campaignId?: number | null;
  columnMapping: any;
  estimatedTotalRows?: number | null;
  fileName: string;
  listType: ListType;
}

export interface Pagination {
  limit?: number | null;
  page?: number | null;
  search?: string | null;
  sortDirection?: SortDirection | null;
  sortField?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
