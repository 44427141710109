import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import { useOktaAuth } from '@okta/okta-react';
import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { AktifyLogoImage } from 'images';
import { ExitToAppIconIcon } from '../../../icons';
import messages from './messages';
import useStyles from './styles';
import routes from 'routes';

export const AppHeader: FC<AppBarProps> = ({ children, title }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { oktaAuth } = useOktaAuth();

  const handleLogoutButtonClick = async () => {
    await oktaAuth.signOut();
  };

  return (
    <AppBar className={classes.appBar} color="primary" position="fixed" elevation={0}>
      <Toolbar variant="dense">
        <Link to={routes.dashboard}>
          <AktifyLogoImage className={classes.logo} title={formatMessage(messages.logoTitle)} />
        </Link>
        <Box display="flex" flex={1} />
        <IconButton
          aria-controls="nav-drawer"
          aria-label={formatMessage(messages.logoutButtonAriaLabel)}
          className={classes.logoutButton}
          color="inherit"
          id="nav-drawer-toggle"
          onClick={handleLogoutButtonClick}
          title={formatMessage(messages.logoutButtonAriaLabel)}
        >
          <ExitToAppIconIcon fontSize="small" />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export interface AppBarProps {
  title?: ReactNode;
}
