import { defineMessages } from 'react-intl';

export default defineMessages({
  reviewTitle: {
    id: 'LeadListCreate.reviewTitle',
    defaultMessage: 'Review & Complete Import',
  },
  reviewImportList: {
    id: 'ListReview.reviewImportList',
    defaultMessage: 'Import This List',
  },
});
