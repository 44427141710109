/**
 * For more info on extending the theme and keeping things strongly typed, see:
 * https://material-ui.com/guides/typescript/#customization-of-theme
 */
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

const opacify = (hex: string, opacity: number): string => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const res = result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
        a: opacity,
      }
    : null;
  if (res) {
    const { r, g, b } = res;
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }
  return hex;
};

const aktifyTheme = createMuiTheme({
  /**
   * PALETTE
   */
  palette: {
    type: 'light',
    common: {
      black: '#000',
      white: '#FFF',
    },
    text: {
      primary: '#1B2C52',
      secondary: '#697C93',
    },
    primary: {
      '100': '#CCD6FF',
      '200': '#99ACFF',
      '300': '#6681FF',
      '400': '#3F5FFF',
      '500': '#0026FF',
      '600': '#001DDB',
      '700': '#0015B7',
      '800': '#000F93',
      '900': '#000A7A',
    },
    primaryTransparent: {
      '100': 'rgba(0, 38, 255, 0.08)',
      '200': 'rgba(0, 38, 255, 0.16)',
      '300': 'rgba(0, 38, 255, 0.24)',
      '400': 'rgba(0, 38, 255, 0.32)',
      '500': 'rgba(0, 38, 255, 0.4)',
      '600': 'rgba(0, 38, 255, 0.48)',
    },
    success: {
      '100': '#C9FCD9',
      '200': '#95F9BE',
      '300': '#5EECA6',
      '400': '#36DB9A',
      '500': '#00c389',
      '600': '#00A786',
      '700': '#008C7E',
      '800': '#007170',
      '900': '#00545D',
    },
    successTransparent: {
      '100': 'rgba(0, 195, 137, 0.08)',
      '200': 'rgba(0, 195, 137, 0.16)',
      '300': 'rgba(0, 195, 137, 0.24)',
      '400': 'rgba(0, 195, 137, 0.32)',
      '500': 'rgba(0, 195, 137, 0.4)',
      '600': 'rgba(0, 195, 137, 0.48)',
    },
    info: {
      '100': '#F3F8FA',
      '200': '#E7F1F6',
      '300': '#D0DDE5',
      '400': '#B5C2CC',
      '500': '#919eab',
      '600': '#697C93',
      '700': '#495D7B',
      '800': '#2E4063',
      '900': '#1B2C52',
    },
    infoTransparent: {
      '100': 'rgba(145, 158, 171, 0.08)',
      '200': 'rgba(145, 158, 171, 0.16)',
      '300': 'rgba(145, 158, 171, 0.24)',
      '400': 'rgba(145, 158, 171, 0.32)',
      '500': 'rgba(145, 158, 171, 0.4)',
      '600': 'rgba(145, 158, 171, 0.48)',
    },
    warning: {
      '100': '#FFFBE3',
      '200': '#FFF5C8',
      '300': '#FFEEAC',
      '400': '#FFE898',
      '500': '#ffdd76',
      '600': '#DBB656',
      '700': '#B7923B',
      '800': '#937025',
      '900': '#7A5716',
    },
    warningTransparent: {
      '100': 'rgba(255, 221, 118, 0.08)',
      '200': 'rgba(255, 221, 118, 0.16)',
      '300': 'rgba(255, 221, 118, 0.24)',
      '400': 'rgba(255, 221, 118, 0.32)',
      '500': 'rgba(255, 221, 118, 0.4)',
      '600': 'rgba(255, 221, 118, 0.48)',
    },
    error: {
      '100': '#FCE2D4',
      '200': '#FABEAB',
      '300': '#F2917F',
      '400': '#E6665D',
      '500': '#d62c2f',
      '600': '#B82030',
      '700': '#9A162F',
      '800': '#7C0E2C',
      '900': '#66082B',
    },
    errorTransparent: {
      '100': 'rgba(214, 44, 47, 0.08)',
      '200': 'rgba(214, 44, 47, 0.16)',
      '300': 'rgba(214, 44, 47, 0.24)',
      '400': 'rgba(214, 44, 47, 0.32)',
      '500': 'rgba(214, 44, 47, 0.4)',
      '600': 'rgba(214, 44, 47, 0.48)',
    },
    grey: {
      '100': '#F3F8FA',
      '200': '#E7F1F6',
      '300': '#D0DDE5',
      '400': '#B5C2CC',
      '500': '#919eab',
      '600': '#697C93',
      '700': '#495D7B',
      '800': '#2E4063',
      '900': '#1B2C52',
    },
    divider: '#E7F1F6',
    background: {
      paper: '#FFF',
      default: '#FAFAFA',
    },
    action: {
      active: '#E7F1F6',
      hover: '#FAFAFA',
      hoverOpacity: 1,
      selected: '#FAFAFA',
      selectedOpacity: 1,
      disabled: '#F3F8FA',
      disabledBackground: '#B5C2CC',
      disabledOpacity: 1,
      focus: '#FF0000',
      focusOpacity: 1,
      activatedOpacity: 1,
    },
  },

  /**
   * TYPEOGRAPHY
   */
  typography: {
    fontSize: 14, // default
    htmlFontSize: 14, // default
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 700,
    fontWeightBold: 900,
    fontFamily: 'Lato',
    button: {
      textTransform: 'none',
      fontWeight: 900,
    },
    h1: {
      fontWeight: 900,
      fontSize: '2rem',
      lineHeight: 2.15,
    },
    h2: {
      fontWeight: 900,
      fontSize: '1.75rem',
      lineHeight: '2.57rem',
    },
    h3: {
      fontWeight: 700,
      fontSize: '1.5rem',
      lineHeight: 1.75,
    },
    h4: {
      fontWeight: 700,
      fontSize: '1.25rem',
      lineHeight: 1.4,
    },
    h5: {
      fontWeight: 700,
      fontSize: '1rem',
      lineHeight: 1.15,
    },
    h6: {
      fontWeight: 700,
      fontSize: '0.8rem',
      lineHeight: 0.95,
    },
    subtitle1: {
      fontWeight: 700,
      fontSize: '1rem',
      lineHeight: 1.15,
    },
    subtitle2: {
      fontWeight: 700,
      fontSize: '0.858rem',
      lineHeight: 1,
    },
    body1: {
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.15,
    },
    body2: {
      fontWeight: 400,
      fontSize: '0.858rem',
      lineHeight: 0.85,
    },
    caption: {},
    overline: {},
  },

  /**
   * OTHER
   */
  shape: {
    borderRadius: 8,
  },
  shadows: [
    'none',
    '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
    '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  ],

  /**
   * CUSTOM
   */
  layout: {
    drawerWidth: 280,
  },
  interactions: {
    tooltipDelay: 300,
  },
  utils: {
    opacify,
  },
});

/**
 * COMPONENT STYLE OVERRIDES
 */

aktifyTheme.overrides = {
  MuiButton: {
    sizeLarge: {
      height: '48px',
      paddingLeft: '30px',
      paddingRight: '30px',
    },
    sizeSmall: {
      fontSize: '1rem',
      fontWeight: 900,
      height: '32px',
      paddingLeft: '24px',
      paddingRight: '24px',
    },
    root: {
      '&:hover': {
        backgroundColor: aktifyTheme.palette.info[100],
      },
    },
    label: {
      fontWeight: 600,
    },
  },

  MuiBackdrop: {
    root: {
      backgroundColor: 'rgba(27, 44, 82, 0.6)',
      backdropFilter: 'blur(8px)',
    },
  },

  MuiTableCell: {
    root: {
      borderBottom: 'none',
      fontSize: '0.858rem',
      lineHeight: '1rem',
      padding: '1rem',
    },
    head: {
      color: aktifyTheme.palette.info[600],
      fontWeight: 400,
      backgroundColor: aktifyTheme.palette.info[100],
      lineHeight: '1rem',
      '&:first-child': {
        borderRadius: '10px 0 0 10px',
      },
      '&:last-child': {
        borderRadius: '0 10px 10px 0',
      },
    },
  },

  MuiMenu: {
    paper: {
      boxShadow: '0px 8px 16px #131A3414',
    },
  },

  MuiIconButton: {
    root: {
      color: aktifyTheme.palette.info[500],
      width: '32px',
      height: '32px',
      '&$disabled': {
        color: aktifyTheme.palette.info[400],
      },
    },
  },

  MuiTableSortLabel: {
    root: {
      '&.MuiTableSortLabel-active': {
        color: aktifyTheme.palette.info[700],
        fontWeight: 700,
      },
    },
  },

  MuiMenuItem: {
    root: {
      color: aktifyTheme.palette.info[600],
    },
  },

  MuiListItemText: {
    root: {
      color: aktifyTheme.palette.info[600],
    },
  },
  MuiListItemIcon: {
    root: {
      minWidth: '28px',
      color: aktifyTheme.palette.info[600],
    },
  },

  MuiInputLabel: {
    outlined: {
      transform: 'translate(14px, 17px) scale(1)',
    },
  },

  MuiSelect: {
    selectMenu: {
      padding: '0px 14px',
      height: '48px',
      minHeight: '48px',
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      color: aktifyTheme.palette.grey[500],
    },
  },
};

export { aktifyTheme };
