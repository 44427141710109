import { ThemeProvider } from '@material-ui/core/styles';
import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { GraphqlProvider } from 'contexts/apolloGraphql';
import { IntlProvider } from 'react-intl';
import { Router } from 'react-router-dom';
import App from './App';
import { aktifyTheme } from './theme';
import * as serviceWorker from './serviceWorker';
import './index.css';

const history = createBrowserHistory();

ReactDOM.render(
  <IntlProvider locale="en" timeZone="America/Denver">
    <GraphqlProvider>
      <ThemeProvider theme={aktifyTheme}>
        <Router history={history}>
          <App />
        </Router>
      </ThemeProvider>
    </GraphqlProvider>
  </IntlProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
